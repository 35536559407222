import { ethers } from 'ethers';
import {
  UiPoolDataProvider,
  UiIncentiveDataProvider,
  ChainId,
} from '@aave/contract-helpers';
import * as markets from '@bgd-labs/aave-address-book';
import { formatReserves, formatUserSummary } from '@aave/math-utils';
import dayjs from 'dayjs';

import config from './config';

const SMALL_BALANCE_USD = 0.04;

const {PROVIDER_URL, AAVE_UI_POOL_DATA_PROVIDER, AAVE_UI_INCENTIVE_DATA_PROVIDER, AAVE_CHAIN_ID, AAVE_POOL_ADDRESSES_PROVIDER} = config;

// ES5 Alternative imports
//  const {
//    ChainId,
//    UiIncentiveDataProvider,
//    UiPoolDataProvider,
//  } = require('@aave/contract-helpers');
//  const markets = require('@bgd-labs/aave-address-book');
//  const ethers = require('ethers');

// Sample RPC address for querying ETH mainnet
const provider = new ethers.providers.JsonRpcProvider(
  //'https://eth-mainnet.public.blastapi.io',
  //'https://eth-sepolia.public.blastapi.io',
  PROVIDER_URL
);

// User address to fetch data for, insert address here
// const currentAccount = '0x5a6e5fe82536c16a678e859a404a6a8f7d870cb3';

// View contract used to fetch all reserves data (including market base currency data), and user reserves
// Using Aave V3 Eth Mainnet address for demo
const poolDataProviderContract = new UiPoolDataProvider({
  //uiPoolDataProviderAddress: markets.AaveV3Ethereum.UI_POOL_DATA_PROVIDER,
  uiPoolDataProviderAddress: AAVE_UI_POOL_DATA_PROVIDER,
  provider,
  //chainId: ChainId.mainnet,
  chainId: AAVE_CHAIN_ID,
});

export const fetchAaveReserves = async() => {
  const reserves = await poolDataProviderContract.getReservesHumanized({
    lendingPoolAddressProvider: AAVE_POOL_ADDRESSES_PROVIDER,
  });
  return reserves;
}

export const fetchAaveReservesWithETH = async() => {
  var reserves = await poolDataProviderContract.getReservesHumanized({
    lendingPoolAddressProvider: AAVE_POOL_ADDRESSES_PROVIDER,
  });
  console.log("909090909")
  console.log(reserves);
  const justWETHFiltered = reserves.reservesData.filter(entry => entry.symbol.toLowerCase() === "weth");
  if (justWETHFiltered.length === 1) {
    var justWETH = [{ ...justWETHFiltered[0] }];
    const oldReservesData = reserves.reservesData.slice()
    justWETH[0].id = "ID_ETH"
    justWETH[0].name = "ETH";
    justWETH[0].symbol = "ETH";
    reserves.reservesData = justWETH.concat(oldReservesData);
  }
  return reserves;
}

export const fetchContractData = async(account) => {
  console.log("ACCOUNT ", account);
  console.log("POOL CONTRACT ", AAVE_POOL_ADDRESSES_PROVIDER);
  // Object containing array of pool reserves and market base currency data
  // { reservesArray, baseCurrencyData }
  const reserves = await poolDataProviderContract.getReservesHumanized({
    //lendingPoolAddressProvider: markets.AaveV3Ethereum.POOL_ADDRESSES_PROVIDER,
    lendingPoolAddressProvider: AAVE_POOL_ADDRESSES_PROVIDER,
  });

  console.log(reserves)
  console.log("REZERVE");

  // Object containing array or users aave positions and active eMode category
  // { userReserves, userEmodeCategoryId }
  const userReserves = await poolDataProviderContract.getUserReservesHumanized({
    //lendingPoolAddressProvider: markets.AaveV3Ethereum.POOL_ADDRESSES_PROVIDER,
    lendingPoolAddressProvider: AAVE_POOL_ADDRESSES_PROVIDER,
    user: account,
  });

    const reservesArray = reserves.reservesData;
    const baseCurrencyData = reserves.baseCurrencyData;

    const currentTimestamp = dayjs().unix();

    /*
    - @param `reserves` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.reservesArray`
    - @param `currentTimestamp` Current UNIX timestamp in seconds
    - @param `marketReferencePriceInUsd` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferencePriceInUsd`
    - @param `marketReferenceCurrencyDecimals` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferenceCurrencyDecimals`
    */
    const formattedPoolReserves = formatReserves({
    reserves: reservesArray,
    currentTimestamp,
    marketReferenceCurrencyDecimals:
        baseCurrencyData.marketReferenceCurrencyDecimals,
    marketReferencePriceInUsd: baseCurrencyData.marketReferenceCurrencyPriceInUsd,
    });

    const userReservesArray = userReserves.userReserves;

    /*
    - @param `currentTimestamp` Current UNIX timestamp in seconds, Math.floor(Date.now() / 1000)
    - @param `marketReferencePriceInUsd` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferencePriceInUsd`
    - @param `marketReferenceCurrencyDecimals` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferenceCurrencyDecimals`
    - @param `userReserves` Input from [Fetching Protocol Data](#fetching-protocol-data), combination of `userReserves.userReserves` and `reserves.reservesArray`
    - @param `userEmodeCategoryId` Input from [Fetching Protocol Data](#fetching-protocol-data), `userReserves.userEmodeCategoryId`
    */
    const userSummary = formatUserSummary({
        currentTimestamp,
        marketReferencePriceInUsd: baseCurrencyData.marketReferenceCurrencyPriceInUsd,
        marketReferenceCurrencyDecimals:
        baseCurrencyData.marketReferenceCurrencyDecimals,
        userReserves: userReservesArray,
        formattedReserves: formattedPoolReserves,
        userEmodeCategoryId: userReserves.userEmodeCategoryId,
    });

    const nonZeroUserReserves = userSummary.userReservesData.filter(entry => Number(entry.scaledATokenBalance) > 0);

    console.log(nonZeroUserReserves);

    var loanValues = [];

    nonZeroUserReserves.forEach(function (oneAssetUserReserves) {
        console.log("====");
        console.log(oneAssetUserReserves);

        const healthFactor = userSummary.currentLiquidationThreshold * userSummary.totalCollateralUSD / userSummary.totalBorrowsUSD;
        console.log("HF = ", healthFactor);

        const collateralAtLiquidation = userSummary.totalBorrowsUSD / userSummary.currentLiquidationThreshold;
        console.log("collateral at liquidation = ", collateralAtLiquidation);

        console.log("current underlaying collateral asset = ", oneAssetUserReserves.reserve.symbol);
        console.log(`current price of ${oneAssetUserReserves.reserve.symbol} = ${oneAssetUserReserves.reserve.priceInUSD} USD`);

        const priceOfUnderlayingAssetPerCoinAtLiquidation = collateralAtLiquidation / oneAssetUserReserves.underlyingBalance;
        console.log(`price of ${oneAssetUserReserves.reserve.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`);
        const percentageDecreaseNeededToLiquidate = (oneAssetUserReserves.reserve.priceInUSD - priceOfUnderlayingAssetPerCoinAtLiquidation) / oneAssetUserReserves.reserve.priceInUSD;

        console.log(`the collateral needs to fall by ${userSummary.currentLiquidationThreshold * userSummary.totalCollateralUSD - collateralAtLiquidation} USD in total`);
        console.log(`the underlaying collateral asset (${oneAssetUserReserves.reserve.symbol}) would need to fall by ${percentageDecreaseNeededToLiquidate*100}% to be liquidated`);

        const borrowedUserReserves = userSummary.userReservesData.filter(entry => Number(entry.totalBorrows) > 0);
        var borrowedAsset = {};
        if (borrowedUserReserves.length == 1) {
          const borrowedOneAsset = borrowedUserReserves[0];
          console.log("Borrowed one asset print")
          console.log(borrowedOneAsset);
          console.log(`Borrowed asset ${borrowedOneAsset.reserve.symbol}, amount ${1} worth ${borrowedOneAsset.totalBorrowsUSD}`);
          borrowedAsset = {
            assetSymbol: borrowedOneAsset.reserve.symbol,
            amount: borrowedOneAsset.totalBorrows,
            amountUSD: borrowedOneAsset.totalBorrowsUSD,
          }
        }

        const oneLoanElement = {
          healthFactor: healthFactor,
          collateralAtLiquidation: collateralAtLiquidation,
          assetSymbol: oneAssetUserReserves.reserve.symbol,
          priceOfAssetInUSD: oneAssetUserReserves.reserve.priceInUSD,
          priceOfUnderlayingAssetPerCoinAtLiquidation: priceOfUnderlayingAssetPerCoinAtLiquidation,
          percentageDecreaseNeededToLiquidate: percentageDecreaseNeededToLiquidate,
          userReserves: oneAssetUserReserves,
          borrowed: borrowedAsset,
        }

        loanValues.push(oneLoanElement);
    })

    return loanValues;
}

export const fetchOpenPositions = async(account) => {
  console.log("ACCOUNT ", account);
  console.log("POOL CONTRACT ", AAVE_POOL_ADDRESSES_PROVIDER);
  // Object containing array of pool reserves and market base currency data
  // { reservesArray, baseCurrencyData }
  const reserves = await poolDataProviderContract.getReservesHumanized({
    //lendingPoolAddressProvider: markets.AaveV3Ethereum.POOL_ADDRESSES_PROVIDER,
    lendingPoolAddressProvider: AAVE_POOL_ADDRESSES_PROVIDER,
  });

  console.log(reserves)
  console.log("REZERVE");

  // Object containing array or users aave positions and active eMode category
  // { userReserves, userEmodeCategoryId }
  const userReserves = await poolDataProviderContract.getUserReservesHumanized({
    //lendingPoolAddressProvider: markets.AaveV3Ethereum.POOL_ADDRESSES_PROVIDER,
    lendingPoolAddressProvider: AAVE_POOL_ADDRESSES_PROVIDER,
    user: account,
  });
    const reservesArray = reserves.reservesData;
    const baseCurrencyData = reserves.baseCurrencyData;

    const currentTimestamp = dayjs().unix();

    /*
    - @param `reserves` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.reservesArray`
    - @param `currentTimestamp` Current UNIX timestamp in seconds
    - @param `marketReferencePriceInUsd` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferencePriceInUsd`
    - @param `marketReferenceCurrencyDecimals` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferenceCurrencyDecimals`
    */
    const formattedPoolReserves = formatReserves({
    reserves: reservesArray,
    currentTimestamp,
    marketReferenceCurrencyDecimals:
        baseCurrencyData.marketReferenceCurrencyDecimals,
    marketReferencePriceInUsd: baseCurrencyData.marketReferenceCurrencyPriceInUsd,
    });

    const userReservesArray = userReserves.userReserves;

    /*
    - @param `currentTimestamp` Current UNIX timestamp in seconds, Math.floor(Date.now() / 1000)
    - @param `marketReferencePriceInUsd` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferencePriceInUsd`
    - @param `marketReferenceCurrencyDecimals` Input from [Fetching Protocol Data](#fetching-protocol-data), `reserves.baseCurrencyData.marketReferenceCurrencyDecimals`
    - @param `userReserves` Input from [Fetching Protocol Data](#fetching-protocol-data), combination of `userReserves.userReserves` and `reserves.reservesArray`
    - @param `userEmodeCategoryId` Input from [Fetching Protocol Data](#fetching-protocol-data), `userReserves.userEmodeCategoryId`
    */
    const userSummary = formatUserSummary({
        currentTimestamp,
        marketReferencePriceInUsd: baseCurrencyData.marketReferenceCurrencyPriceInUsd,
        marketReferenceCurrencyDecimals:
        baseCurrencyData.marketReferenceCurrencyDecimals,
        userReserves: userReservesArray,
        formattedReserves: formattedPoolReserves,
        userEmodeCategoryId: userReserves.userEmodeCategoryId,
    });

    var nonZeroUserReserves = userSummary.userReservesData.filter(entry => Number(entry.underlyingBalanceUSD) > SMALL_BALANCE_USD);

    console.log(nonZeroUserReserves);
    console.log("00000000000000000")

    const justWETHFiltered = nonZeroUserReserves.filter(entry => entry.reserve.symbol.toLowerCase() === "weth");
    console.log(justWETHFiltered);
    console.log("0000000000000000000")
    if (justWETHFiltered.length === 1) {
      //var justWETH = [{ ...justWETHFiltered[0] }];
      //const oldReservesData = nonZeroUserReserves.slice()
      justWETHFiltered[0].id = "ID_ETH"
      justWETHFiltered[0].reserve.id = "ID_ETH"
      justWETHFiltered[0].reserve.name = "ETH";
      justWETHFiltered[0].reserve.symbol = "ETH";
      //nonZeroUserReserves = justWETH.concat(oldReservesData);
    }

    var loanValues = [];

    nonZeroUserReserves.forEach(function (oneAssetUserReserves) {
        console.log("====");
        console.log(oneAssetUserReserves);

        // if (oneAssetUserReserves.symbol.toLowerCase() === "weth") {
        //   oneAssetUserReserves.symbol = "ETH";
        //   oneAssetUserReserves.name = "ETH";
        //   oneAssetUserReserves.id = "ID_ETH";
        // }

        const healthFactor = userSummary.currentLiquidationThreshold * userSummary.totalCollateralUSD / userSummary.totalBorrowsUSD;
        console.log("HF = ", healthFactor);

        const collateralAtLiquidation = userSummary.totalBorrowsUSD / userSummary.currentLiquidationThreshold;
        console.log("collateral at liquidation = ", collateralAtLiquidation);

        console.log("current underlaying collateral asset = ", oneAssetUserReserves.reserve.symbol);
        console.log(`current price of ${oneAssetUserReserves.reserve.symbol} = ${oneAssetUserReserves.reserve.priceInUSD} USD`);

        const priceOfUnderlayingAssetPerCoinAtLiquidation = collateralAtLiquidation / oneAssetUserReserves.underlyingBalance;
        console.log(`price of ${oneAssetUserReserves.reserve.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`);
        const percentageDecreaseNeededToLiquidate = (oneAssetUserReserves.reserve.priceInUSD - priceOfUnderlayingAssetPerCoinAtLiquidation) / oneAssetUserReserves.reserve.priceInUSD;

        console.log(`the collateral needs to fall by ${userSummary.currentLiquidationThreshold * userSummary.totalCollateralUSD - collateralAtLiquidation} USD in total`);
        console.log(`the underlaying collateral asset (${oneAssetUserReserves.reserve.symbol}) would need to fall by ${percentageDecreaseNeededToLiquidate*100}% to be liquidated`);
    })

    const nonZeroBorrowedUserReserves = userSummary.userReservesData.filter(entry => Number(entry.totalBorrows) > SMALL_BALANCE_USD);

    return { nonZeroUserReserves, nonZeroBorrowedUserReserves, userSummary };
}