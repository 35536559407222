import { Container } from './Container'

const faqs = [
  [
    {
      question: "Why choose DeFi Koala?",
      answer: "At DeFi Koala, our mission is to make DeFi accessible to everyone, especially those who find traditional DeFi platforms intimidating. We achieve this by offering a user interface designed specifically for newcomers, making it easier for them to explore the incredible opportunities in DeFi. Our vision is to provide one-click access to a variety of DeFi products, simplifying the entire experience. Currently, we offer a 1-click loan feature, which we believe is the most user-friendly option for those who may have been deterred by the complexity of other platforms like AAVE or Compound. DeFi Koala is here to bridge the gap and make DeFi intuitive and approachable for all."
    },
    {
      question: "What is a 1-Click Crypto Loan?",
      answer: "A 1-Click Crypto Loan is a streamlined process that allows you to borrow crypto assets from Aave with just a single click. Our platform simplifies the traditional borrowing steps, making it faster and easier to get the funds you need."
    },
    {
      question: "What is Aave?",
      answer: "Aave is one of the leading decentralized finance (DeFi) platforms, enabling users to lend and borrow cryptocurrencies without the need for intermediaries. It operates on the Ethereum blockchain and offers a wide range of assets, allowing users to earn interest on deposits and take out loans. However, the process of borrowing on Aave can be complex for those new to DeFi, requiring multiple steps and a deep understanding of the platform. DeFi Koala bridges this gap by simplifying the borrowing process into a single click, making it more intuitive and accessible for everyone."
    },
    {
      question: "How does your platform work?",
      answer: "Our platform connects directly to Aave, a decentralized lending protocol, through a smart contract. This contract calls the `borrow` function on Aave, allowing you to secure a loan instantly. Additionally, we use Uniswap’s Permit2 contract, which reduces the need for repeated ERC20 token approvals if you’ve already granted permissions via Permit2."
    },
  ],
  [
    {
      question: "What is the Permit2 contract, and how does it help me?",
      answer: "The Permit2 contract is a feature from Uniswap that allows for gas-efficient approvals of ERC20 tokens. If you've previously used Permit2, our platform can leverage those approvals, saving you the hassle and gas costs of approving tokens multiple times when borrowing."
    },
    {
      question: "Do I need to have an account on Aave to use this service?",
      answer: "No, you don’t need a separate Aave account. Our platform handles the integration, so you can interact with Aave’s lending pools directly through our simplified UI."
    },
    {
      question: "What assets can I borrow using this service?",
      answer: "You can borrow any assets supported by Aave, including popular cryptocurrencies like ETH, USDC, DAI, and more. The available assets will be displayed in the borrowing interface."
    },
    {
      question: "How do I repay the loan?",
      answer: "Repaying the loan is just as easy as borrowing. You can repay the borrowed amount directly through our platform, which will handle the repayment process with Aave. The platform will also display any accrued interest, so you know exactly what you owe."
    },
    {
      question: "What are the fees associated with using this platform?",
      answer: "Our platform does not charge any additional fees beyond what Aave charges for borrowing. However, you will still need to cover the gas fees for transactions on the Ethereum network."
    },
    {
      question: "Is my collateral safe?",
      answer: "Yes, your collateral is securely held within the Aave protocol. Our platform simply interfaces with Aave to manage your loan but does not hold your collateral or assets."
    }
  ],
  [
    {
      question: "What happens if I don’t repay my loan?",
      answer: "If you fail to repay your loan, your collateral may be liquidated by Aave according to their liquidation process. Our platform shows how much your collateral is at risk, giving you an opportunity to take action."
    },
    {
      question: "How do I know my transactions are secure?",
      answer: "We prioritize your security by integrating with well-established protocols like Aave and Uniswap. Additionally, all transactions are executed on-chain, meaning they are transparent and verifiable on the Ethereum blockchain."
    },
    {
      question: "Are you holding my coins?",
      answer: "No. Defi Koala works with self-custodial wallets, currently it supports Metamask only. At no point we are holding your tokens, all the transactions are signed exclusively by the end user."
    },
    {
      question: "Can I use this service on mobile devices?",
      answer: "Our platform is optimized for desktop only for now, but we are constantly working on improving the user experience."
    },
    {
      question: "Who can I contact for support?",
      answer: "If you have any questions or run into issues, you can contact our support team via email at [community@defikoala.com]."
    }
  ]
];


export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, email our support team community@defikoala.com
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
