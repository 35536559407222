export function Logo(props) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 109 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="15" cy="20" r="10" fill="#A0A0A0" />
      <circle cx="5" cy="10" r="5" fill="#A0A0A0" />
      <circle cx="25" cy="10" r="5" fill="#A0A0A0" />
      <circle cx="11" cy="18" r="2" fill="#000" />
      <circle cx="19" cy="18" r="2" fill="#000" />
      <ellipse cx="15" cy="22" rx="3" ry="4" fill="#000" />
      <path
        d="M12,25 Q15,28 18,25"
        stroke="#000"
        stroke-width="2"
        fill="none"
      />

      <text
        x="30"
        y="25"
        font-family="Arial, sans-serif"
        font-size="16"
        fill="#000"
        font-weight="bold"
      >
        <tspan fill="#000">DeFi</tspan>
        <tspan fill="#A0A0A0">Koala</tspan>
      </text>
    </svg>
  );
}
