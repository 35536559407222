import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { fetchAaveReservesWithETH, fetchContractData } from './positions';
import { borrow } from './borrow';

import config, { SUPPORTED_NETWORKS, CONFIG } from './config';

import { Hero } from './components/Hero';
import { Borrows } from './components/Borrows';
import { Faqs } from './components/Faqs';
import { Footer } from './components/Footer'

import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const AAVE_LENDING_POOL_ABI = [
  // Simplified ABI with only the functions we need
  "function getUserAccountData(address user) view returns (uint256 totalCollateralETH, uint256 totalDebtETH, uint256 availableBorrowsETH, uint256 currentLiquidationThreshold, uint256 ltv, uint256 healthFactor)",
];

//const AAVE_LENDING_POOL_ADDRESS = '0x87870Bca3F3fD6335C3F4ce8392D69350B4fA4E2'; // Mainnet address
const AAVE_LENDING_POOL_ADDRESS = '0x0562453c3dafbb5e625483af58f4e6d668c44e19'; // Sepolia address

function BorrowForm({account, provider, signer, reserves}) {
  const [amountOwned, setAmountOwned] = useState('10000');
  const [amountToBorrow, setAmountToBorrow] = useState('5000');
  const [crypto, setCrypto] = useState('ETH');
  const [loading, setLoading] = useState(false);
  const [selectedSupplyAssetName, setSelectedSupplyAssetName] = useState('');
  const [selectedSupplyIndex, setSelectedSupplyIndex] = useState(null);
  const [selectedSupplyAsset, setSelectedSupplyAsset] = useState(null);
  const firstReserve = reserves && reserves.length > 0 ? reserves[0] : null;

  const supplyAsset = selectedSupplyAsset != null ? selectedSupplyAsset : firstReserve;

  const [selectedBorrowAssetName, setSelectedBorrowAssetName] = useState('');
  const [selectedBorrowIndex, setSelectedBorrowIndex] = useState(null);
  const [selectedBorrowAsset, setSelectedBorrowAsset] = useState(null);

  const borrowAsset = selectedBorrowAsset != null ? selectedBorrowAsset : firstReserve;

  const [showPopup, setShowPopup] = useState(false);


  // useEffect(() => {
  //   if (reserves && reserves.length > 0) {
  //     setSelectedSupplyAssetName(reserves[0].name);
  //     setSelectedSupplyIndex(0);
  //     setSelectedSupplyAsset(reserves[0]);
  //     setSelectedBorrowAssetName(reserves[0].name);
  //     setSelectedBorrowIndex(0);
  //     setSelectedBorrowAsset(reserves[0]);
  //   }
  // }, [reserves]);

  const handleAmountOwnedChange = (e) => {
    setAmountOwned(e.target.value);
  };

  const handleAmountToBorrowChange = (e) => {
    setAmountToBorrow(e.target.value);
  };

  const handleCryptoChange = (e) => {
    setCrypto(e.target.value);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!account) {
      alert("Please login with Metamask first.");
      return;
    }

    //setShowPopup(true);

    // alert(`I have ${amountOwned} $ worth of ${crypto} and want to borrow ${amountToBorrow} $`);

    const permit2Address = '0x000000000022D473030F116dDEE9F6B43aC78BA3';

    // const tokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // DAI token //'<Token_Address>';
    // const aTokenAddress = '0x22675C506A8FC26447aFFfa33640f6af5d4D4cF0';
    // const debtTokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // '0x6d906e526a4e2ca02097ba9d0caa3c382f52278e'; // EURS token // '<Debt_Token_Address>';
    
    console.log({supplyAsset})
    console.log({borrowAsset})
    console.log("END")

    console.log(`Supplying ${supplyAsset.name} to borrow ${borrowAsset.name} ...`);

    const tokenAddress = supplyAsset.underlyingAsset; // DAI token //'<Token_Address>';
    const aTokenAddress = supplyAsset.variableDebtTokenAddress;
    const debtTokenAddress = borrowAsset.underlyingAsset; //
    const aDebtTokenAddress = borrowAsset.variableDebtTokenAddress;

    console.log(tokenAddress);
    console.log(aTokenAddress);
    
    const lendingPoolAddress = config.DEFI_KOALA_LENDING_CONTRACT; //'0x43da3abea427c2bee3a960a5101b99261cd723b3'; //0x95B02439Bfc648E244cbEaDf3a7095d20Ac14f1E'; // Sepolia 1click loan contract // '<Lending_Pool_Address>';
    const amount = ethers.utils.parseUnits('300', supplyAsset.decimals); // Adjust the amount as necessary
    const debtAmount = ethers.utils.parseUnits('50', borrowAsset.decimals); // ethers.utils.parseUnits('5000', 2); // Adjust the debt amount as necessary
    const delegateApproveAmount = ethers.utils.parseUnits('100000000', supplyAsset.decimals); // TODO: check if aToken and Token always have same decimals
    const delegateApproveBorrowAmount = ethers.utils.parseUnits('100000000', borrowAsset.decimals); // TODO: check if aToken and Token always have same decimals

    setLoading(true);
    try {
      console.log("Called borrow");
      const currentTime = Math.floor(Date.now() / 1000) + 3600; // deadline
      const startTime = performance.now();
      await borrow(signer, tokenAddress, amount, provider, permit2Address, aTokenAddress, aDebtTokenAddress, lendingPoolAddress, delegateApproveAmount, delegateApproveBorrowAmount, currentTime, debtTokenAddress, debtAmount);
      const endTime = performance.now();
      const timeSpent = endTime - startTime;
      console.log(`Time spent borrowing: ${timeSpent} ms`);
    } catch (err) {
      console.log("Error while borrowing:", err);
    }
    setLoading(false);

  };

  const handleSupplyChange = (event) => {
    setSelectedSupplyAssetName(event.target.value);
    setSelectedSupplyIndex(event.target.selectedIndex);
    setSelectedSupplyAsset(reserves[event.target.selectedIndex]);
    console.log("supply asset")
    console.log(selectedSupplyIndex)
    console.log(selectedSupplyAsset);
    console.log(reserves[event.target.selectedIndex]);
    console.log("supply asset END");
    console.log(reserves)
    console.log(`Selected supply asset: ${event.target.value}`);
    console.log(`Selected supply index: ${event.target.selectedIndex}`);
  };

  const handleBorrowChange = (event) => {
    setSelectedBorrowAssetName(event.target.value);
    setSelectedBorrowIndex(event.target.selectedIndex);
    setSelectedBorrowAsset(reserves[event.target.selectedIndex]);
    console.log("borrow asset")
    console.log(selectedBorrowAsset);
    console.log(reserves[event.target.selectedIndex]);
    console.log("borrow asset END")
    console.log(`Selected borrow asset: ${event.target.value}`);
    console.log(`Selected borrow index: ${event.target.selectedIndex}`);
  };

  const handleBorrow = () => {
    // Add borrowing logic here
    setShowPopup(false);
    console.log(`Borrowing ${amountToBorrow} USDC using ${crypto}`);
  };

  // const handleAmountToBorrowChange = (e) => {
  //   setAmountToBorrow(e.target.value);
  //   if (parseFloat(e.target.value) <= parseFloat(amountOwned)) {
  //     setInputError(false);
  //   }
  // };

  const riskExplanation = parseFloat(amountToBorrow) > 0.5 * parseFloat(amountOwned) 
    ? "This is a high-risk borrow amount. Consider borrowing less to minimize your risk."
    : "This is a low-risk borrow amount.";

  return (
    <div>
      <h1>DEFI Borrowing Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            I have 
            <input 
              type="number" 
              value={amountOwned} 
              onChange={handleAmountOwnedChange} 
              placeholder="0.00"
            /> 
            $ worth of 
            <select value={selectedSupplyAssetName} onChange={handleSupplyChange}>
              {reserves ? (reserves.map((reserve, index) => (
                reserve.usageAsCollateralEnabled ? <option key={index} value={reserve.address}>{reserve.symbol}</option> : <></>
              ))) : <></>}
              {/* <option value="ETH">ETH</option>
              <option value="BTC">BTC</option>
              <option value="BNB">BNB</option>
              <option value="ADA">ADA</option>
              <option value="SOL">SOL</option>
              <option value="XRP">XRP</option> */}
            </select>
          </label>
        </div>
        <div>
          <label>
            and want to borrow 
            <input 
              type="number" 
              value={amountToBorrow} 
              onChange={handleAmountToBorrowChange} 
              placeholder="0.00"
            /> 
            $ worth of 
            <select value={selectedBorrowAssetName} onChange={handleBorrowChange}>
              {reserves ? (reserves.map((reserve, index) => (
                reserve.borrowingEnabled ? <option key={index} value={reserve.address}>{reserve.symbol}</option> : <option hidden={true} key={index} value={reserve.address}>{reserve.symbol}</option>
              ))) : <></>}
            </select>
          </label>
        </div>
        <button type="submit">Submit</button>
      </form>
      {loading && <p>Loading...</p>}

      <div className="flex items-center justify-center h-screen bg-gray-100">
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Borrow Confirmation</h2>
            {/* TODO: this should be just a confirmation popup! */}
            <label>Use {' '}
             as collateral
               {crypto} to borrow
             {amountToBorrow} USDC</label>
            <button onClick={handleBorrow}>Borrow</button>
            <button onClick={() => setShowPopup(false)}>Cancel</button>
          </div>
        </div>
      )}
      </div>
    </div>
  );
}

function OpenPositions({ account, networkId }) {
  const [positions, setPositions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  useEffect(() => {

    if (account) {
      const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
          const res = await fetchContractData(account);
          console.log("-======-")
          console.log(res);

          // const provider = new ethers.providers.Web3Provider(window.ethereum);
          // const contract = new ethers.Contract(AAVE_LENDING_POOL_ADDRESS, AAVE_LENDING_POOL_ABI, provider);
          // const data = await contract.getUserAccountData(account);
          // console.log("DATA++++")
          // console.log(data);
          
          // setPositions({
          //   totalCollateralETH: ethers.utils.formatEther(data.totalCollateralETH),
          //   totalDebtETH: ethers.utils.formatEther(data.totalDebtETH),
          //   availableBorrowsETH: ethers.utils.formatEther(data.availableBorrowsETH),
          // });

          setPositions(res);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Error loading data.');
        }

        setLoading(false);
      };

      fetchData();
    }
  }, [account]);

  if (!account) {
    return <div>Please connect your MetaMask wallet.</div>;
  }

  console.log("mreze");
  console.log(config.NETWORK_ID, networkId);
  if (config.NETWORK_ID !== networkId) {
    return <div>Please select supported network id.</div>;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Current Open Positions</h1>
      {positions ? (
      <div>
      <table>
        <thead>
          <tr>
            <th>Collateral Asset</th>
            <th>Collateral Amount</th>
            <th>Collateral (USD)</th>
            <th>Borrowed Asset</th>
            <th>Borrowed Amount</th>
            <th>Borrowed (USD)</th>
            <th>Health Factor</th>
          </tr>
        </thead>
        <tbody>
          {positions.map((position, index) => (
            <tr key={index}>
              <td>{position.assetSymbol}</td>
              <td>{Number(position.userReserves.underlyingBalance).toFixed(2)}</td>
              <td>{Number(position.userReserves.underlyingBalanceUSD).toFixed(2)}</td>
              <td>{position.borrowed.assetSymbol}</td>
              <td>{Number(position.borrowed.amount).toFixed(2)}</td>
              <td>{Number(position.borrowed.amountUSD).toFixed(2)}</td>
              <td>{Number(position.healthFactor).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="position-description">
        {positions.map((position, index) => (
              <div>
                <br></br>
                Current price of 1 {position.assetSymbol} is {Number(position.priceOfAssetInUSD).toFixed(2)} USD
                <h2>Liquidation</h2>
                If the collateral value reaches {Number(position.collateralAtLiquidation).toFixed(2)} in USD you risk getting liquidated
                <br></br>
                The {position.assetSymbol} needs to fall by {Number(position.percentageDecreaseNeededToLiquidate*100).toFixed(2)}% to risk liquidation
                <br></br>
                Price of {position.assetSymbol} at liquidation would be {Number(position.priceOfUnderlayingAssetPerCoinAtLiquidation).toFixed(2)} USD
              </div>
        ))}
      <button onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}>
        {isDescriptionVisible ? 'Hide Description' : 'Show Description'}
      </button>
      <div className={`position-description-2 ${isDescriptionVisible ? 'visible' : 'hidden'}`}>
        <p>
          This table shows your current open positions in the Aave protocol. For each asset, you can see the amount you have provided as collateral, its equivalent value in USD, the amount you have borrowed, and the borrowed amount's equivalent value in USD.
        </p>
        <p>
          Ensuring your collateral is sufficient compared to your borrowed amount is crucial to avoid liquidation. The health factor helps determine the safety of your position. A health factor above 1 means your collateral value sufficiently covers your borrowed amount. Below 1 indicates potential liquidation risk.
        </p>
      </div>
      
      </div>
      </div> ) : (
        <p>No open positions found.</p>
      )}
    </div>
  );
}

function HomePage({connectMetaMask, account, networkId, provider, signer}) {
  const [reserves, setReserves] = useState(null);
  const [baseCurrencyData, setBaseCurrencyData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchAvailableAssets = async () => {
      try {
        const reservesList = await fetchAaveReservesWithETH();
        console.log("***************")
        console.log(reservesList);
        console.log("***************")
        setReserves(reservesList.reservesData);
        setBaseCurrencyData(reservesList.baseCurrencyData);
      } catch (err) {
        console.log('nije uhvatio')
        console.log(err.message);
      }
    };

    fetchAvailableAssets();
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

  }, [location.pathname, location.search]);

  return (
    <div className="HomePage">
      <main>
        <Hero account={account} provider={provider} signer={signer} reserves={reserves} baseCurrencyData={baseCurrencyData}/> 
        <Borrows account={account} provider={provider} signer={signer} reserves={reserves} baseCurrencyData={baseCurrencyData} config={config} networkId={networkId} loginMetamask={connectMetaMask}/> 
        <Faqs />
      </main>
    </div>
  );
}

export default HomePage;
