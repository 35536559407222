import React, {useEffect, useState} from 'react';
import { ethers } from 'ethers';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Faqs } from './components/Faqs';
import { Blog } from './components/Blog';
import HomePage from './HomePage';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { TestnetBanner } from './components/TestnetBanner';
import config, { SUPPORTED_NETWORKS, CONFIG } from './config';

function App() {
  const [account, setAccount] = useState(null);
  const [networkName, setNetworkName] = useState(null);
  const [networkId, setNetworkId] = useState(null);
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(web3Provider);
      web3Provider.send('eth_accounts', []).then(accounts => {
        if (accounts.length > 0) {
          const web3Signer = web3Provider.getSigner();
          setSigner(web3Signer);
          setAccount(accounts[0]);
          getNetworkName();
          console.log('eth_accounts ', accounts[0]);
        }
      });

      window.ethereum.on('accountsChanged', accounts => {
        if (accounts.length > 0) {
          const web3Signer = web3Provider.getSigner();
          setSigner(web3Signer);
          setAccount(accounts[0]);
          getNetworkName();
          console.log('accountsChanged ', accounts[0]);
        } else {
          setSigner(null);
          setAccount(null);
        }
      });

      window.ethereum.on('chainChanged', function () {
        getNetworkName();
      });
    }

  }, []);

  const getNetworkName = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      setNetworkId(network.chainId);
      if (SUPPORTED_NETWORKS.includes(network.chainId)) {
        const networkConfig = Object.values(CONFIG).find(config => config.NETWORK_ID === network.chainId);
        setNetworkName(networkConfig.NETWORK_NAME);
      } else {
        alert(`Unsupported network. Please connect to one of the supported networks: ${SUPPORTED_NETWORKS.join(', ')}`);
      }
    }
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(web3Provider);
        const web3Signer = web3Provider.getSigner();
        setSigner(web3Signer);
        getNetworkName();
      } catch (error) {
        console.error("MetaMask connection error:", error);
      }
    } else {
      alert("Please install MetaMask!");
    }
  };

  const disconnectMetaMask = () => {
    setAccount(null);
    setSigner(null);
  }

  return (
    <div className='App'>
      <Router>
        <TestnetBanner config={config}/>
        <Header onClick={connectMetaMask} disconnectMetaMask={disconnectMetaMask} account={account}/>  
        <Routes>
          <Route path="/" element={<HomePage connectMetaMask={connectMetaMask} account={account} networkId={networkId} provider={provider}  signer={signer}/>} /> {/* Home page route */}
          <Route path="/faq" element={<Faqs />} />   {/* FAQ page route */}
          <Route path="/blog" element={<Blog />} />   {/* Blog page route */}
          {/* Add other routes here */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;