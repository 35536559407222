"use client";

import {
  Popover,
  PopoverButton,
  PopoverBackdrop,
  PopoverPanel,
} from "@headlessui/react";
import clsx from "clsx";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useState } from "react";

import { Button } from "./Button";
import { Container } from "./Container";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import { Link, useLocation } from "react-router-dom";

function MobileNavLink({ href, children }) {
  return (
    <PopoverButton href={href} className="block w-full p-2">
      {children}
    </PopoverButton>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          open && "scale-90 opacity-0"
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !open && "scale-90 opacity-0"
        )}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <PopoverBackdrop
        transition
        className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <PopoverPanel
        transition
        className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <MobileNavLink href="#borrow">Borrow</MobileNavLink>
        <MobileNavLink href="#positions">Positions</MobileNavLink>
        {/* <MobileNavLink href="#blog">Blog</MobileNavLink> */}
        <MobileNavLink href="#faq">FAQ</MobileNavLink>
      </PopoverPanel>
    </Popover>
  );
}

export function Header({ onClick, account, disconnectMetaMask }) {
  const location = useLocation();

  const [showPopup, setShowPopup] = useState(false); // To show/hide popup

  const HeaderButtons = () => {
    if (location.pathname === "/blog") {
      return (
        <div className="hidden md:flex md:gap-x-6">
          <Link
            to="/"
            className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 cursor-pointer"
            onClick={(e) => {
              window.scrollTo({
                top: 0,
                behavior: "instant", // This makes the scroll smooth
              });
            }}
          >
            Positions
          </Link>
          <Link
            to="/"
            className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 cursor-pointer"
            onClick={(e) => {
              window.scrollTo({
                top: 0,
                behavior: "instant", // This makes the scroll smooth
              });
            }}
          >
            FAQ
          </Link>
          <Link
            to="/blog"
            className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 cursor-pointer"
          >
            Blog
          </Link>
        </div>
      );
    }

    return (
      <div className="hidden md:flex md:gap-x-6">
        <NavLink to="positions">Positions</NavLink>
        <NavLink to="faq">FAQ</NavLink>
        <Link
          to="/blog"
          className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 cursor-pointer"
        >
          Blog
        </Link>
      </div>
    );
  };

  const handleButtonClick = () => {
    if (account) {
      setShowPopup(!showPopup); // Show/Hide popup if already connected
    } else {
      onClick(); // Connect if not connected
    }
  };

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between" id="header">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <Logo className="h-10 w-auto" />
            </Link>
            {HeaderButtons()}
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden md:block">
              <Button onClick={handleButtonClick}>
                {account != null ? account : "Connect Metamask"}
              </Button>
              {showPopup && (
                <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  <div className="px-4 py-3">
                    <Button
                      onClick={() => {
                        setShowPopup(false);
                        disconnectMetaMask();
                      }}
                    >
                      Disconnect
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
