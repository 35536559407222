import React, { useState, useEffect } from 'react';
import { Button } from './Button'
import { Container } from './Container'
import { fetchOpenPositions } from '../positions';
// import { Pool, AaveV3Sepolia_POOL } from '@aave/contract-helpers';
import { ethers } from 'ethers';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { borrow, checkAndApproveAllowance, getMaxFeeData, submitRepayPermit, approveDelegation, signPermit, justBorrow, submitSupplyPermit, depositETH, withdrawETH } from '../borrow';

export function Borrows({account, provider, signer, reserves, baseCurrencyData, config, networkId, loginMetamask}) {
  const [positions, setPositions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [additionalBorrowAmount, setAdditionalBorrowAmount] = useState('0');
  const [additionalSupplyAmount, setAdditionalSupplyAmount] = useState('0');
  const [additionalRepayAmount, setAdditionalRepayAmount] = useState('0');
  const [additionalWithdrawAmount, setAdditionalWithdrawAmount] = useState('0');

  const [showPopup, setShowPopup] = useState(false);
  const [notEnoughSupplyTokenText, setNotEnoughTokenText] = useState('');
  const [borrowRiskText, setBorrowRiskText] = useState('');
  const [healthFactor, setHealthFactor] = useState(10000.0);
  const [borrowValueInvalid, setBorrowValueInvalid] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [steps, setSteps] = useState([
  ]);
  const [borrowEnded, setBorrowEnded] = useState(2);

  const [chosenPopup, setChosenPopup] = useState(0);

  useEffect(() => {

    if (account) {
      const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
          const res = await fetchOpenPositions(account);
          console.log("-======-")
          console.log(res);
          setPositions(res);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Error loading data.');
        }

        setLoading(false);
      };

      fetchData();
    }
  }, [account]);

  const OpenPositions = () => {

    if (!account) {
      return (
      <div className='mb-4'>
        <span className="block text-gray-700 mb-1 text-3xl"> 
          Please connect your MetaMask wallet to see your borrow positions
        </span>
        <br />

        <div className="hidden md:block">
          <Button onClick={loginMetamask}>{account != null ? account : "Connect Metamask"}</Button>
        </div>

      </div>
      );
    }
  
    console.log("mreze");
    console.log(config.NETWORK_ID, networkId);
    if (config.NETWORK_ID !== networkId) {
      return (
        <div className='mb-4'>
          <span className="block text-gray-700 mb-1 text-3xl"> 
            Please select supported network id in Metamask, it should be {config.NETWORK_NAME}
          </span>
        </div>
        );
    }
  
    if (loading) {
      return (
        <div className='mb-4'>
          <span className="block text-gray-700 mb-1 text-3xl"> 
            Loading...
          </span>
        </div>
        );
    }
    if (error) {
      return (
        <div className='mb-4'>
          <span className="block text-gray-700 mb-1 text-3xl"> 
            There was an error loading your positions, please try again a bit later.
          </span>
        </div>
      );
    }

    if (!positions || positions.nonZeroUserReserves.length === 0 ) {
      return (
        <div className='mb-4'>
          <span className="block text-gray-700 mb-1 text-3xl"> 
            You have no open borrow positions.
          </span>
        </div>
      );
    }

    const collateralAtLiquidation = positions.userSummary.totalBorrowsUSD / positions.userSummary.currentLiquidationThreshold;
    const priceOfUnderlayingAssetPerCoinAtLiquidation = collateralAtLiquidation / parseFloat(positions.nonZeroUserReserves[0].underlyingBalance);
    const percentageDecreaseNeededToLiquidate = (positions.nonZeroUserReserves[0].reserve.priceInUSD - priceOfUnderlayingAssetPerCoinAtLiquidation) / positions.nonZeroUserReserves[0].reserve.priceInUSD * 100;

    const percentageDropClassName = () => {
      if (percentageDecreaseNeededToLiquidate >= 50) {
        return "text-green-500";
      }
      if (percentageDecreaseNeededToLiquidate >= 25) {
        return "text-orange-500";
      }
      return "text-red-500";
    }

    var decimalsToRound = 2;
    if (["eth", "wbtc", "wsteth", "weth"].includes(positions.nonZeroUserReserves[0].reserve.symbol.toLowerCase())) {
      decimalsToRound = 4;
    }

    const collateralText = () => {
      return (
        <div className='mb-4'>
          <div className="tracking-normal text-gray-700 text-2xl">
            Currently 1 {positions.nonZeroUserReserves[0].reserve.symbol} is worth {parseFloat(positions.nonZeroUserReserves[0].reserve.formattedPriceInMarketReferenceCurrency).toFixed(2)
}$, if it drops <span className={`text-5xl ${percentageDropClassName()}`}>{percentageDecreaseNeededToLiquidate.toFixed(2)}%</span> to {priceOfUnderlayingAssetPerCoinAtLiquidation.toFixed(2)}$, you risk liquidation.
            {/* The collateral value at liqudation is {collateralAtLiquidation.toFixed(2)}$ */}
          </div>
          <br />
        <span className="block text-gray-700 mb-1 text-2xl"> 
          You have supplied {parseFloat(positions.nonZeroUserReserves[0].underlyingBalance).toFixed(decimalsToRound)} {positions.nonZeroUserReserves[0].reserve.symbol} 
        </span> 
        <span className="block text-gray-700 mb-1 text-2xl">
          worth {parseFloat(positions.nonZeroUserReserves[0].underlyingBalanceUSD).toFixed(2)}$ as collateral
        </span>
        </div>
      );
    }

    const borrowForm = () => {
      const hasBorrows = positions.nonZeroBorrowedUserReserves.length > 0
      const text = hasBorrows ?
       `Your debt is ${parseFloat(positions.nonZeroBorrowedUserReserves[0].totalBorrows).toFixed(2)} ${positions.nonZeroBorrowedUserReserves[0].reserve.symbol} worth ${parseFloat(positions.nonZeroBorrowedUserReserves[0].totalBorrowsUSD).toFixed(2)}$`
       : `You have no debt.`
      return (
        <form>
          <div className="mb-4">
            <span className="block text-gray-700 mb-1 text-2xl">
              {text}
            </span>
          </div>
          {hasBorrows && (
            <div>
              <Button className="tracking-normal" type="submit" onClick={borrowMore}>Borrow more</Button>
              {' '}
              <Button className="tracking-normal" type="submit" onClick={repayMore}>Repay</Button>
            </div>
            )
          } 

        </form>
      )
    }

    const supplyMore = async(e) => {
      e.preventDefault();
      setShowPopup(true);
      setChosenPopup(2);
    }

    const borrowMore = async(e) => {
      e.preventDefault();
      setShowPopup(true);
      setChosenPopup(1);
    }

    const repayMore = async(e) => {
      e.preventDefault();
      setShowPopup(true);
      setChosenPopup(3);
    }

    const withdrawMore = async(e) => {
      e.preventDefault();
      setShowPopup(true);
      setChosenPopup(4);
    }

    return (
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-5xl">
      <form >
        {collateralText()}  
        <Button className="tracking-normal" type="submit" onClick={supplyMore}>Add more collateral</Button>
        {' '}
        <Button className="tracking-normal" type="submit" onClick={withdrawMore}>Withdraw collateral</Button>
      </form>
      <br />
      {borrowForm()}
        
      </h1>
    );
  }

  const afterSucceded = async () => {
    resetBorrow();
    setLoading(true);
    try {
      const res = await fetchOpenPositions(account);
      console.log("-======-")
      console.log(res);
      setPositions(res);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error loading data.');
    }
    setLoading(false);
  }

  const resetBorrow = () => {
    setIsProcessing(false);
    setBorrowRiskText('')
    setSteps([]);
    setShowPopup(false);
    setBorrowEnded(2);
    setChosenPopup(0);
    setHealthFactor(10000);
  }

  const handleCancel = () => {
    resetBorrow();
  };

  const handleConfirm = (e) => {
    if (!account) {
      resetBorrow();
      alert("Please login with Metamask first.");
      return;
    }
    setIsProcessing(true);
    setShowPopup(true);
    if (chosenPopup === 1) {
      handleBorrow(e);
    } else if (chosenPopup === 2) {
      handleSupply(e);
    } else if (chosenPopup === 3) {
      handleRepay(e);
    } else if (chosenPopup === 4) {
      handleWithdraw(e);
    }
    
  };

  function truncateToXDecimals(number, X) {
    const numString = number.toString();
  
    const [integerPart, decimalPart] = numString.split('.');
  
    if (!decimalPart || X === 0) {
      return integerPart;
    }
  
    const truncatedDecimal = decimalPart.substring(0, X);
  
    return `${integerPart}.${truncatedDecimal}`;
  }

  const handleWithdraw = async(e) => {
    e.preventDefault();
    if (!account) {
      alert("Please login with Metamask first.");
      return;
    }
    const lendingPoolABI = [
      "function withdraw(address asset, uint256 amount, address to) external returns (uint256)",
    ];

    const lendingPool = new ethers.Contract(config.POOL, lendingPoolABI, signer);

    var currentSteps = [];
    var iter = -1;

    const assetAddress = positions.nonZeroUserReserves[0].underlyingAsset;
    const decimals = positions.nonZeroUserReserves[0].reserve.decimals;
    const amount = ethers.utils.parseUnits(truncateToXDecimals(additionalWithdrawAmount, decimals), decimals); // Amount to repay (e.g., 100 USDC with 6 decimals)
    const userAddress = await signer.getAddress(); // Your Ethereum address

    try {

      if (positions.nonZeroUserReserves[0].id === "ID_ETH") {

        const aTokenAddress = positions.nonZeroUserReserves[0].reserve.aTokenAddress;
        console.log(aTokenAddress);
        const shouldGoIn1 = await checkAndApproveAllowance(signer, aTokenAddress, amount, provider, config.AAVE_WETH_GATEWAY, true);
        console.log(shouldGoIn1);
        console.log("NJANJANJANAJNA")
      if (shouldGoIn1) {
        const billionSupply = ethers.utils.parseUnits('1000000000', decimals);
      try {
        iter++;
        currentSteps.push({text: `Approving allowance for aEthWETH`, status: 'in-progress'});
        setSteps(currentSteps);
        await checkAndApproveAllowance(signer, aTokenAddress, billionSupply, provider, config.AAVE_WETH_GATEWAY);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }
      }        

      // const shouldGoIn2 = await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, amount, true);
      // if (shouldGoIn2) {
      // try {
      //   iter++;
      //   currentSteps.push({text: `Approving delegation for ${supplyAsset.symbol}`, status: 'in-progress'});
      //   setSteps(currentSteps);
      //   await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, amount);
      //   var currentSteps2 = currentSteps.slice();
      //   currentSteps2[iter].status = 'success';
      //   setSteps(currentSteps2);
      //   currentSteps = currentSteps2.slice();
      // } catch (err) {
      //   var currentSteps2 = currentSteps.slice();
      //   currentSteps2[iter].status = 'error';
      //   setSteps(currentSteps2);
      //   setBorrowEnded(0);
      //   return false;
      // }
      // }
      }

      // Withdraw collateral
      iter++;
      
      currentSteps.push({text: 'Withdrawing', status: 'in-progress'});
      var currentSteps2 = currentSteps.slice();
      setSteps(currentSteps2);
      currentSteps = currentSteps2.slice();
      var txRepay;
      if (positions.nonZeroUserReserves[0].id === "ID_ETH") {
        txRepay = await withdrawETH(config.AAVE_WETH_GATEWAY, config.POOL, signer, provider, amount, {
          gasLimit: ethers.utils.hexlify(1500000)
        });
      } else {
        txRepay = await lendingPool.withdraw(assetAddress, amount, userAddress, {
          gasLimit: ethers.utils.hexlify(1500000)
        });
        await txRepay.wait();
      }
      var currentSteps2 = currentSteps.slice();
      currentSteps2[iter].status = 'success';
      setSteps(currentSteps2);
      currentSteps = currentSteps2.slice();
      console.log("Withdraw transaction success");
      setBorrowEnded(1);
    } catch (error) {
      var currentSteps2 = currentSteps.slice();
      currentSteps2[iter].status = 'error';
      setSteps(currentSteps2);
      currentSteps = currentSteps2.slice();
      setBorrowEnded(0);
      console.error("Error repaying debt:", error);
    }

  }

  const handleBorrow = async(e) => {
    e.preventDefault();
    if (!account) {
      alert("Please login with Metamask first.");
      return;
    }

    var currentSteps = [];
    var iter = -1;
    const lendingPoolAddress = config.DEFI_KOALA_LENDING_CONTRACT; //'0x43da3abea427c2bee3a960a5101b99261cd723b3';
    const debtTokenAddress = positions.nonZeroBorrowedUserReserves[0].underlyingAsset;
    const aDebtTokenAddress = positions.nonZeroBorrowedUserReserves[0].reserve.variableDebtTokenAddress;
    const borrowAsset = positions.nonZeroBorrowedUserReserves[0].reserve;
    //positions.nonZeroBorrowedUserReserves[0].reserve.variableDebtTokenAddress;
    const debtAmount = ethers.utils.parseUnits(additionalBorrowAmount, positions.nonZeroBorrowedUserReserves[0].reserve.decimals);
    const delegateApproveBorrowAmount = ethers.utils.parseUnits('1000000000', positions.nonZeroBorrowedUserReserves[0].reserve.decimals); 

    const shouldGoIn3 = await approveDelegation(aDebtTokenAddress, lendingPoolAddress, delegateApproveBorrowAmount, signer, provider, debtAmount, true);
      if (shouldGoIn3) {
      try {
        iter++;
        
        currentSteps.push({text: `Approving delegation for ${borrowAsset.symbol}`, status: 'in-progress'});
        var currentSteps2 = currentSteps.slice();
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        await approveDelegation(aDebtTokenAddress, lendingPoolAddress, delegateApproveBorrowAmount, signer, provider, debtAmount);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }
      }

    currentSteps.push({text: 'Borrowing', status: 'in-progress'});
    setSteps(currentSteps);
    try {
          iter++;
          await justBorrow(lendingPoolAddress, provider, signer, debtTokenAddress, debtAmount);
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'success';
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(1);
    } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'error';
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(0);
          return false;
    }
  }

  const handleSupply = async(e) => {
    e.preventDefault();
    if (!account) {
      alert("Please login with Metamask first.");
      return;
    }

    const permit2Address = '0x000000000022D473030F116dDEE9F6B43aC78BA3';

    // const tokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // DAI token //'<Token_Address>';
    // const aTokenAddress = '0x22675C506A8FC26447aFFfa33640f6af5d4D4cF0';
    // const debtTokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // '0x6d906e526a4e2ca02097ba9d0caa3c382f52278e'; // EURS token // '<Debt_Token_Address>';
    

    //console.log(`Supplying ${supplyAsset.name} to borrow ${borrowAsset.name} ...`);

    const supplyAsset = positions.nonZeroUserReserves[0].reserve;
    const tokenAddress = supplyAsset.underlyingAsset; // DAI token //'<Token_Address>';
    const aTokenAddress = supplyAsset.variableDebtTokenAddress;

    console.log(tokenAddress);
    console.log(aTokenAddress);
    
    const lendingPoolAddress = config.DEFI_KOALA_LENDING_CONTRACT; //'0x43da3abea427c2bee3a960a5101b99261cd723b3'; //0x95B02439Bfc648E244cbEaDf3a7095d20Ac14f1E'; // Sepolia 1click loan contract // '<Lending_Pool_Address>';
    // const amount = ethers.utils.parseUnits('300', supplyAsset.decimals); // Adjust the amount as necessary
    // const debtAmount = ethers.utils.parseUnits('50', borrowAsset.decimals); // ethers.utils.parseUnits('5000', 2); // Adjust the debt amount as necessary

    const amount = ethers.utils.parseUnits(additionalSupplyAmount, positions.nonZeroUserReserves[0].reserve.decimals);

    const delegateApproveAmount = ethers.utils.parseUnits('1000000000', supplyAsset.decimals); // TODO: check if aToken and Token always have same decimals

    const billionSupply = ethers.utils.parseUnits('1000000000', supplyAsset.decimals);

    try {
      console.log("Called supply");
      const deadline = Math.floor(Date.now() / 1000) + 3600; // deadline
      const startTime = performance.now();
      //await borrow(signer, tokenAddress, amount, provider, permit2Address, aTokenAddress, aDebtTokenAddress, lendingPoolAddress, delegateApproveAmount, delegateApproveBorrowAmount, currentTime, debtTokenAddress, debtAmount);

      var currentSteps = [];
      var iter = -1;

      if (supplyAsset.id === "ID_ETH") {
        try {
          iter++;
          currentSteps.push({text: `Depositing ETH`, status: 'in-progress'});
          setSteps(currentSteps);
          await depositETH(config.AAVE_WETH_GATEWAY, config.POOL, signer, provider, amount);
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'success';
          setSteps(currentSteps2);
          setBorrowEnded(1);
          currentSteps = currentSteps2.slice();
          return true;
        } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'error';
          setSteps(currentSteps2);
          setBorrowEnded(0);
          return false;
        }
      }

      const shouldGoIn1 = await checkAndApproveAllowance(signer, tokenAddress, amount, provider, permit2Address, true);
      if (shouldGoIn1) {
      try {
        iter++;
        currentSteps.push({text: `Approving allowance for ${supplyAsset.symbol}`, status: 'in-progress'});
        setSteps(currentSteps);
        await checkAndApproveAllowance(signer, tokenAddress, billionSupply, provider, permit2Address);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }
      }

      const shouldGoIn2 = await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, amount, true);
      if (shouldGoIn2) {
      try {
        iter++;
        // TODO: see why is this called all the time for WBTC!
        currentSteps.push({text: `Approving delegation for ${supplyAsset.symbol}`, status: 'in-progress'});
        setSteps(currentSteps);
        await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, amount);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        setBorrowEnded(0);
        return false;
      }
      }

      const callerAddress = await signer.getAddress();
      const nonce = await provider.getTransactionCount(callerAddress);
      console.log("NONCE = ", nonce);

      currentSteps.push({text: 'Signing Permit', status: 'in-progress'});
      setSteps(currentSteps);
      var permitSignature;
      try {
        iter++;
        permitSignature = await signPermit(signer, provider, tokenAddress, permit2Address, amount, lendingPoolAddress, deadline, nonce);
        console.log("Potpisano: ", permitSignature);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }

      currentSteps.push({text: 'Supplying', status: 'in-progress'});
        setSteps(currentSteps);
        try {
          iter++;
          await submitSupplyPermit(permitSignature, lendingPoolAddress, provider, signer, tokenAddress, amount, deadline, nonce);
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'success';
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(1);
        } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'error';
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(0);
          return false;
        }

      const endTime = performance.now();
      const timeSpent = endTime - startTime;
      console.log(`Time spent supplying: ${timeSpent} ms`);
    } catch (err) {
      console.log("Error while supplying:", err);
    }
    return true;
  }

  const handleRepay = async(e) => {
    e.preventDefault();
    if (!account) {
      alert("Please login with Metamask first.");
      return;
    }

    const permit2Address = '0x000000000022D473030F116dDEE9F6B43aC78BA3';

    // const tokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // DAI token //'<Token_Address>';
    // const aTokenAddress = '0x22675C506A8FC26447aFFfa33640f6af5d4D4cF0';
    // const debtTokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // '0x6d906e526a4e2ca02097ba9d0caa3c382f52278e'; // EURS token // '<Debt_Token_Address>';
    

    //console.log(`Supplying ${supplyAsset.name} to borrow ${borrowAsset.name} ...`);

    const borrowAsset = positions.nonZeroBorrowedUserReserves[0].reserve;
    const tokenAddress = borrowAsset.underlyingAsset; // DAI token //'<Token_Address>';
    const aTokenAddress = borrowAsset.variableDebtTokenAddress;

    console.log(tokenAddress);
    console.log(aTokenAddress);
    
    const lendingPoolAddress = config.DEFI_KOALA_LENDING_CONTRACT; //'0x43da3abea427c2bee3a960a5101b99261cd723b3'; //0x95B02439Bfc648E244cbEaDf3a7095d20Ac14f1E'; // Sepolia 1click loan contract // '<Lending_Pool_Address>';
    // const amount = ethers.utils.parseUnits('300', supplyAsset.decimals); // Adjust the amount as necessary
    // const debtAmount = ethers.utils.parseUnits('50', borrowAsset.decimals); // ethers.utils.parseUnits('5000', 2); // Adjust the debt amount as necessary

    const amount = ethers.utils.parseUnits(additionalRepayAmount, borrowAsset.decimals);

    const delegateApproveAmount = ethers.utils.parseUnits('1000000000', borrowAsset.decimals); // TODO: check if aToken and Token always have same decimals

    const billionSupply = ethers.utils.parseUnits('1000000000', borrowAsset.decimals);

    try {
      console.log("Called repay");
      const deadline = Math.floor(Date.now() / 1000) + 3600; // deadline
      const startTime = performance.now();
      //await borrow(signer, tokenAddress, amount, provider, permit2Address, aTokenAddress, aDebtTokenAddress, lendingPoolAddress, delegateApproveAmount, delegateApproveBorrowAmount, currentTime, debtTokenAddress, debtAmount);

      var currentSteps = [];
      var iter = -1;
      const shouldGoIn1 = await checkAndApproveAllowance(signer, tokenAddress, amount, provider, permit2Address, true);
      if (shouldGoIn1) {
      try {
        iter++;
        currentSteps.push({text:`Approving allowance for ${borrowAsset.symbol}`, status: 'in-progress'});
        setSteps(currentSteps);
        await checkAndApproveAllowance(signer, tokenAddress, billionSupply, provider, permit2Address);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }
      }

      const shouldGoIn2 = await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, amount, true);
      if (shouldGoIn2) {
      try {
        iter++;
        currentSteps.push({text: `Approving delegation for ${borrowAsset.symbol}`, status: 'in-progress'});
        setSteps(currentSteps);
        await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, amount);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        setBorrowEnded(0);
        return false;
      }
      }

      const callerAddress = await signer.getAddress();
      const nonce = await provider.getTransactionCount(callerAddress);
      console.log("NONCE = ", nonce);

      currentSteps.push({text: 'Signing Permit', status: 'in-progress'});
      setSteps(currentSteps);
      var permitSignature;
      try {
        iter++;
        permitSignature = await signPermit(signer, provider, tokenAddress, permit2Address, amount, lendingPoolAddress, deadline, nonce);
        console.log("Potpisano: ", permitSignature);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'success';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = 'error';
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }

      currentSteps.push({text: `Repaying ${borrowAsset.symbol}`, status: 'in-progress'});
        setSteps(currentSteps);
        try {
          iter++;
          await submitRepayPermit(permitSignature, lendingPoolAddress, provider, signer, tokenAddress, amount, deadline, nonce);
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'success';
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(1);
        } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = 'error';
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(0);
          return false;
        }

      const endTime = performance.now();
      const timeSpent = endTime - startTime;
      console.log(`Time spent repaying: ${timeSpent} ms`);
    } catch (err) {
      console.log("Error while repaying:", err);
    }
    return true;
  }

  function processInput(input) {
    // Trim any whitespace from the input
    input = input.trim();

    // If the input is an empty string or contains only zeros, return "0"
    if (input === "" || /^0+$/.test(input)) {
        return "0";
    }

    // Split the input by commas, process each segment, and then rejoin them
    return input
        .split(',')
        .map(segment => String(Number(segment))) // Convert each segment to a number to remove leading zeros, then back to a string
        .join(',');
}
  

  const handleBorrowAmountChange = (e) => {
    setAdditionalBorrowAmount(e.target.value);
    const input = processInput(e.target.value);
    if (input === "0") {
      setHealthFactor(0);
      setBorrowRiskText("Can't borrow 0$");
      return;
    }
    const amountToBorrow = parseFloat(input);

    const supplyAsset = positions.nonZeroUserReserves[0];
    const borrowAsset = positions.nonZeroBorrowedUserReserves[0];
    const userSummary = positions.userSummary;

    const amountToBorrowInUSD = amountToBorrow * borrowAsset.reserve.priceInUSD;

    const newTotalBorrow = parseFloat(userSummary.totalBorrowsUSD) + amountToBorrowInUSD

    const healthFactor = supplyAsset.reserve.baseLTVasCollateral / 10000 
      * parseFloat(userSummary.totalCollateralUSD) / newTotalBorrow;

    const aaveHealthFactor = supplyAsset.reserve.reserveLiquidationThreshold / 10000 
      * parseFloat(userSummary.totalCollateralUSD) / newTotalBorrow;

    const collateralAtLiquidation = newTotalBorrow / (supplyAsset.reserve.reserveLiquidationThreshold / 10000);

    const amountOwnedInSupply = parseFloat(supplyAsset.underlyingBalance);

    const priceOfUnderlayingAssetPerCoinAtLiquidation = collateralAtLiquidation / amountOwnedInSupply;
    console.log(`price of ${supplyAsset.reserve.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`);
    const percentageDecreaseNeededToLiquidate = (parseFloat(supplyAsset.reserve.priceInUSD) - priceOfUnderlayingAssetPerCoinAtLiquidation) / parseFloat(supplyAsset.reserve.priceInUSD) * 100;

    const oldHF = parseFloat(userSummary.currentLiquidationThreshold) * parseFloat(userSummary.totalCollateralUSD) / parseFloat(userSummary.totalBorrowsUSD);

    //const riskText = (`Old HF = ${oldHF}, New HF = ${healthFactor}\n  Your total new debt would be ${newTotalBorrow.toFixed(2)}$\nOne ${supplyAsset.reserve.symbol} is currently worth ${supplyAsset.reserve.priceInUSD}$. It would need to fall to ${parseFloat(priceOfUnderlayingAssetPerCoinAtLiquidation).toFixed(2)}$ (a fall by ${parseFloat(percentageDecreaseNeededToLiquidate).toFixed(2)}%) to risk liquidation.`);
    const riskText = (`Your total new debt would be ${newTotalBorrow.toFixed(2)}$\nOne ${supplyAsset.reserve.symbol} is currently worth ${supplyAsset.reserve.priceInUSD}$. It would need to fall to ${parseFloat(priceOfUnderlayingAssetPerCoinAtLiquidation).toFixed(2)}$ (a fall by ${parseFloat(percentageDecreaseNeededToLiquidate).toFixed(2)}%) to risk liquidation.`);
    if (healthFactor >= 3.00) {
      setBorrowRiskText(`This is considered a safe borrow.\n ${riskText}`);
    } else if (healthFactor >= 2.00) {
      setBorrowRiskText(`This is considered a mildy risky borrow.\n ${riskText}`);
    } else if (healthFactor >= 1.1) {
      setBorrowRiskText(`This is considered a fairly risky borrow.\n ${riskText}`);
    } else if (healthFactor > 1.0) {
      setBorrowRiskText(`This is considered a very risky borrow.\n ${riskText}`);
    } else {
      const maxToBorrow = parseFloat(userSummary.totalCollateralUSD) * supplyAsset.reserve.baseLTVasCollateral / 10000 - parseFloat(userSummary.totalBorrowsUSD)
      //setBorrowRiskText(`Old HF = ${oldHF}, New HF = ${healthFactor}\nCan't borrow that amount as it is below the liquidation threshold.\n You can borrow additionally at max ${parseFloat(maxToBorrow).toFixed(2)}$ but you would risk immediate liquidation.`);
      setBorrowRiskText(`Can't borrow that amount as it is below the liquidation threshold.\n You can borrow additionally at max ${parseFloat(maxToBorrow).toFixed(2)}$ but you would risk a small fall of collateral for liquidation.`);
    }
    setHealthFactor(healthFactor);
  };

  const handleRepayAmountChange = (e) => {
    setAdditionalRepayAmount(e.target.value);
    const amountToRepay = e.target.value;
    if (parseInt(amountToRepay) === 0 || amountToRepay === "") {
      setHealthFactor(0);
      setBorrowRiskText("Can't repay 0");
      return;
    }

    const supplyAsset = positions.nonZeroUserReserves[0];
    const borrowAsset = positions.nonZeroBorrowedUserReserves[0];
    const userSummary = positions.userSummary;

    const amountToRepayInUSD = amountToRepay * borrowAsset.reserve.priceInUSD;

    const newTotalBorrow = amountToRepayInUSD > parseFloat(userSummary.totalBorrowsUSD) ?
      0 : parseFloat(userSummary.totalBorrowsUSD) - amountToRepayInUSD;

    const healthFactor = amountToRepayInUSD > parseFloat(userSummary.totalBorrowsUSD) ?
      -1 : supplyAsset.reserve.reserveLiquidationThreshold / 10000 
      * parseFloat(userSummary.totalCollateralUSD) / newTotalBorrow;

    const collateralAtLiquidation = newTotalBorrow / (supplyAsset.reserve.reserveLiquidationThreshold / 10000);

    const amountOwnedInSupply = parseFloat(supplyAsset.underlyingBalance);

    const priceOfUnderlayingAssetPerCoinAtLiquidation = collateralAtLiquidation / amountOwnedInSupply;
    console.log(`price of ${supplyAsset.reserve.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`);
    const percentageDecreaseNeededToLiquidate = (parseFloat(supplyAsset.reserve.priceInUSD) - priceOfUnderlayingAssetPerCoinAtLiquidation) / parseFloat(supplyAsset.reserve.priceInUSD) * 100;

    const riskText = (`Your total new debt would be ${newTotalBorrow.toFixed(2)}$\nOne ${supplyAsset.reserve.symbol} is currently worth ${supplyAsset.reserve.priceInUSD}$. It would need to fall to ${parseFloat(priceOfUnderlayingAssetPerCoinAtLiquidation).toFixed(2)}$ (a fall by ${parseFloat(percentageDecreaseNeededToLiquidate).toFixed(2)}%) to risk liquidation.`);
    if (healthFactor >= 3.00) {
      setBorrowRiskText(`Repayment will lower your debt and improve risk.\n ${riskText}`);
    } else if (healthFactor >= 2.00) {
      setBorrowRiskText(`Repayment will lower your debt and improve risk.\n ${riskText}`);
    } else if (healthFactor >= 1.1) {
      setBorrowRiskText(`Repayment will lower your debt and improve risk.\n ${riskText}`);
    } else if (healthFactor > 1.0) {
      setBorrowRiskText(`Repayment will lower your debt and improve risk.\n ${riskText}`);
    } else if (healthFactor <= 0.0) {
      setBorrowRiskText(`Full repayment of debt`);
      setAdditionalRepayAmount(borrowAsset.variableBorrows);
    } else {
      console.error("Health factor can't go below 1 when repaying")
      //setBorrowRiskText(`Can't borrow that amount as it is below the liquidation threshold.\n You can borrow additionally at max ${parseFloat(userSummary.availableBorrowsUSD).toFixed(2)}$ but you would risk immediate liquidation.`);
    }
    console.log(`HEALTH  ${healthFactor}`);
    setHealthFactor(healthFactor);
  };

  const handleWithdrawAmountChange = (e) => {
    setAdditionalWithdrawAmount(parseFloat(e.target.value));
    const input = processInput(e.target.value)
    if (input === "0") {
      setHealthFactor(0);
      setBorrowRiskText("Can't withdraw 0");
      return;
    }
    const amountToWithdraw = parseFloat(input);

    const supplyAsset = positions.nonZeroUserReserves[0];
    const userSummary = positions.userSummary;

    const amountToWithdrawInUSD = amountToWithdraw * supplyAsset.reserve.priceInUSD;

    const newTotalCollateral = amountToWithdrawInUSD > parseFloat(userSummary.totalCollateralUSD) 
      ? 0
      : parseFloat(userSummary.totalCollateralUSD) - amountToWithdrawInUSD;

    const healthFactor = supplyAsset.reserve.reserveLiquidationThreshold / 10000 
      * newTotalCollateral / userSummary.totalBorrowsUSD;

    const collateralAtLiquidation = userSummary.totalBorrowsUSD / (supplyAsset.reserve.reserveLiquidationThreshold / 10000);

    const amountOwnedInSupply = (amountToWithdraw > parseFloat(supplyAsset.underlyingBalance))
      ? 0
      : parseFloat(supplyAsset.underlyingBalance) - amountToWithdraw;

    const collateralAtMin = userSummary.totalBorrowsUSD / supplyAsset.reserve.reserveLiquidationThreshold * 10000;
    const amountCollateralAtMin = collateralAtMin / supplyAsset.reserve.priceInUSD;

    const priceOfUnderlayingAssetPerCoinAtLiquidation = (amountToWithdraw > parseFloat(supplyAsset.underlyingBalance))
      ? collateralAtLiquidation / amountCollateralAtMin
      : collateralAtLiquidation / amountOwnedInSupply;
    console.log(`price of ${supplyAsset.reserve.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`);
    const percentageDecreaseNeededToLiquidate = (parseFloat(supplyAsset.reserve.priceInUSD) - priceOfUnderlayingAssetPerCoinAtLiquidation) / parseFloat(supplyAsset.reserve.priceInUSD) * 100;

    const riskText = (`Your total new collateral would be ${newTotalCollateral.toFixed(2)}$\nOne ${supplyAsset.reserve.symbol} is currently worth ${supplyAsset.reserve.priceInUSD}$. It would need to fall to ${parseFloat(priceOfUnderlayingAssetPerCoinAtLiquidation).toFixed(2)}$ (a fall by ${parseFloat(percentageDecreaseNeededToLiquidate).toFixed(2)}%) to risk liquidation.`);
    if (healthFactor >= 3.00) {
      setBorrowRiskText(`Withdrawing will increase your risk.\n ${riskText}`);
    } else if (healthFactor >= 2.00) {
      setBorrowRiskText(`Withdrawing will increase your risk.\n ${riskText}`);
    } else if (healthFactor >= 1.1) {
      setBorrowRiskText(`Withdrawing will increase your risk.\n ${riskText}`);
    } else if (healthFactor > 1.0) {
      setBorrowRiskText(`Withdrawing will increase your risk.\n ${riskText}`);
    } else if (healthFactor <= 0.0) {
      setBorrowRiskText(`Full withdrawing of collateral.`);
    } else {
      console.error("Health factor can't go below 1 when repaying")
      //setBorrowRiskText(`Can't borrow that amount as it is below the liquidation threshold.\n You can borrow additionally at max ${parseFloat(userSummary.availableBorrowsUSD).toFixed(2)}$ but you would risk immediate liquidation.`);
    }
    // if (amountToWithdrawInUSD > parseFloat(userSummary.totalCollateralUSD)) {
    //   setAdditionalWithdrawAmount(parseFloat(supplyAsset.underlyingBalance) - amountCollateralAtMin);
      
    // } 
    if (amountToWithdraw > parseFloat(supplyAsset.underlyingBalance) - amountCollateralAtMin) {
      setAdditionalWithdrawAmount(truncateToXDecimals((parseFloat(supplyAsset.underlyingBalance) - amountCollateralAtMin - 0.0000001), supplyAsset.reserve.decimals));
      setBorrowRiskText(`Withdrawing max amount of collateral`);
    }
    console.log(`HEALTH  ${healthFactor}`);
    setHealthFactor(healthFactor);
  };

  const handleSupplyAmountChange = (e) => {
    setAdditionalSupplyAmount(e.target.value);
    const amountToSupply = e.target.value;
    if (parseFloat(amountToSupply) === 0 || amountToSupply === "") {
      setHealthFactor(0);
      setBorrowRiskText("Can't supply 0$");
      return;
    }

    const supplyAsset = positions.nonZeroUserReserves[0];
    const borrowAsset = positions.nonZeroBorrowedUserReserves[0];
    const userSummary = positions.userSummary;

    const amountToSupplyInUSD = amountToSupply * supplyAsset.reserve.priceInUSD;

    const newTotalSupply = parseFloat(userSummary.totalCollateralUSD) + amountToSupplyInUSD

    const healthFactor = supplyAsset.reserve.reserveLiquidationThreshold / 10000 
      * newTotalSupply / userSummary.totalBorrowsUSD;

    const collateralAtLiquidation = userSummary.totalBorrowsUSD / (supplyAsset.reserve.reserveLiquidationThreshold / 10000);

    const amountOwnedInSupply = parseFloat(supplyAsset.underlyingBalance) + parseFloat(amountToSupply);

    const priceOfUnderlayingAssetPerCoinAtLiquidation = collateralAtLiquidation / amountOwnedInSupply;
    console.log(`price of ${supplyAsset.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`);
    const percentageDecreaseNeededToLiquidate = (parseFloat(supplyAsset.reserve.priceInUSD) - priceOfUnderlayingAssetPerCoinAtLiquidation) / parseFloat(supplyAsset.reserve.priceInUSD) * 100;

    const riskText = (`Your total new collateral would be ${newTotalSupply.toFixed(2)}$\nOne ${supplyAsset.reserve.symbol} is currently worth ${supplyAsset.reserve.priceInUSD}$. It would need to fall to ${parseFloat(priceOfUnderlayingAssetPerCoinAtLiquidation).toFixed(2)}$ (a fall by ${parseFloat(percentageDecreaseNeededToLiquidate).toFixed(2)}%) to risk liquidation.`);
    if (healthFactor >= 3.00) {
      setBorrowRiskText(`This is considered a great risk improvement.\n ${riskText}`);
    } else if (healthFactor >= 2.00) {
      setBorrowRiskText(`This is considered a fair risk improvement.\n ${riskText}`);
    } else if (healthFactor >= 1.1) {
      setBorrowRiskText(`This is considered a small risk improvement.\n ${riskText}`);
    } else if (healthFactor > 1.0) {
      setBorrowRiskText(`This is considered a tiny risk improvement.\n ${riskText}`);
    } else {
      setBorrowRiskText('No risk improvement');
    }
    setHealthFactor(healthFactor);
  };

  const BorrowMore = () => {

    var supplyInputValueClassnames = "tracking-normal pl-3 pr-10 py-2 rounded-md text-5xl"
    if (borrowValueInvalid) {
      supplyInputValueClassnames += " input-invalid focus:ring-red-500 focus:outline-none";
    } else {
      supplyInputValueClassnames += " focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 focus:outline-none";
    }

    var riskTextClassnames = "tracking-normal text-red-500 text-lg";
    if (healthFactor >= 3.0) {
      riskTextClassnames = "tracking-normal text-green-500 text-lg";
    } else if (healthFactor >= 2.0) {
      riskTextClassnames = "tracking-normal text-orange-500 text-lg";
    } else if (healthFactor >= 1.2) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else if (healthFactor > 1.0) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    }

    return (
      <div className="pb-16 pt-20 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-3xl font-medium tracking-tight text-slate-900 sm:text-5xl">
      <div className='mb-4'>
          <span className="block text-gray-700 mb-1"> 
            Additionally borrow{' '} 
            <input 
              type="number" 
              className={supplyInputValueClassnames}
              value={additionalBorrowAmount} 
              style={{'max-width': '270px'}}
              // style={supplyValueInvalid ? { borderColor: 'red', color: 'red', textarea} : {}}
              onChange={handleBorrowAmountChange} 
              placeholder="100"
            /> 
            {' '} {positions.nonZeroBorrowedUserReserves[0].reserve.symbol}
          </span>
          <br />
          <div className={riskTextClassnames}>
            {borrowRiskText.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <br />
          <div className="tracking-normal flex justify-center space-x-4">
              <Button
                className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className={`px-4 py-2 bg-green-500 text-white hover:bg-green-600 ${healthFactor <= 1.0 ? 'bg-gray-400 cursor-not-allowed' : ''}`}
                onClick={handleConfirm}
                disabled={healthFactor <= 1.0}
              >
                Confirm
              </Button>
            </div>
      </div>
      </h1>
      </div>
    )
  }

  const SupplyMore = () => {

    var supplyInputValueClassnames = "tracking-normal pl-3 pr-10 py-2 rounded-md text-5xl"
    if (borrowValueInvalid) {
      supplyInputValueClassnames += " input-invalid focus:ring-red-500 focus:outline-none";
    } else {
      supplyInputValueClassnames += " focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 focus:outline-none";
    }

    var riskTextClassnames = "tracking-normal text-red-500 text-lg";
    if (healthFactor >= 3.0) {
      riskTextClassnames = "tracking-normal text-green-500 text-lg";
    } else if (healthFactor >= 2.0) {
      riskTextClassnames = "tracking-normal text-orange-500 text-lg";
    } else if (healthFactor >= 1.2) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else if (healthFactor > 1.0) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    }

    return (
      <div className="pb-16 pt-20 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-3xl font-medium tracking-tight text-slate-900 sm:text-5xl">
      <div className='mb-4'>
          <span className="block text-gray-700 mb-1"> 
            Supply additional collateral{' '} 
            <input 
              type="number" 
              className={supplyInputValueClassnames}
              value={additionalSupplyAmount} 
              style={{'max-width': '270px'}}
              // style={supplyValueInvalid ? { borderColor: 'red', color: 'red', textarea} : {}}
              onChange={handleSupplyAmountChange} 
              placeholder="100"
            /> 
            {' '} {positions.nonZeroUserReserves[0].reserve.symbol}
          </span>
          <br />
          <div className={riskTextClassnames}>
            {borrowRiskText.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <br />
          <div className="tracking-normal flex justify-center space-x-4">
              <Button
                className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className={`px-4 py-2 bg-green-500 text-white hover:bg-green-600 ${healthFactor <= 1.0 ? 'bg-gray-400 cursor-not-allowed' : ''}`}
                onClick={handleConfirm}
                disabled={healthFactor <= 1.0}
              >
                Confirm
              </Button>
            </div>
      </div>
      </h1>
      </div>
    )
  }

  const RepayMore = () => {

    var supplyInputValueClassnames = "tracking-normal pl-3 pr-10 py-2 rounded-md text-5xl"
    if (borrowValueInvalid) {
      supplyInputValueClassnames += " input-invalid focus:ring-red-500 focus:outline-none";
    } else {
      supplyInputValueClassnames += " focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 focus:outline-none";
    }

    var riskTextClassnames = "tracking-normal text-red-500 text-lg";
    if (healthFactor >= 3.0) {
      riskTextClassnames = "tracking-normal text-green-500 text-lg";
    } else if (healthFactor >= 2.0) {
      riskTextClassnames = "tracking-normal text-orange-500 text-lg";
    } else if (healthFactor >= 1.2) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else if (healthFactor > 1.0) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else if (healthFactor < 0.0) {
      riskTextClassnames = "tracking-normal text-green-500 text-lg";
    } else {
      console.error("should not happen when repaying")
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    }

    return (
      <div className="pb-16 pt-20 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-3xl font-medium tracking-tight text-slate-900 sm:text-5xl">
      <div className='mb-4'>
          <span className="block text-gray-700 mb-1"> 
            Repay{' '} 
            <input 
              type="number" 
              className={supplyInputValueClassnames}
              value={additionalRepayAmount} 
              style={{'max-width': '270px'}}
              // style={supplyValueInvalid ? { borderColor: 'red', color: 'red', textarea} : {}}
              onChange={handleRepayAmountChange} 
              placeholder="0"
            /> 
            {' '} {positions.nonZeroBorrowedUserReserves[0].reserve.symbol}
          </span>
          <br />
          <div className={riskTextClassnames}>
            {borrowRiskText.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <br />
          <div className="tracking-normal flex justify-center space-x-4">
              <Button
                className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className={`px-4 py-2 bg-green-500 text-white hover:bg-green-600 ${healthFactor <= 1.0 ? '' : ''}`}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
      </div>
      </h1>
      </div>
    )
  }

  const WithdrawMore = () => {

    var supplyInputValueClassnames = "tracking-normal pl-3 pr-10 py-2 rounded-md text-5xl"
    if (borrowValueInvalid) {
      supplyInputValueClassnames += " input-invalid focus:ring-red-500 focus:outline-none";
    } else {
      supplyInputValueClassnames += " focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 focus:outline-none";
    }

    var riskTextClassnames = "tracking-normal text-red-500 text-lg";
    if (healthFactor >= 3.0) {
      riskTextClassnames = "tracking-normal text-green-500 text-lg";
    } else if (healthFactor >= 2.0) {
      riskTextClassnames = "tracking-normal text-orange-500 text-lg";
    } else if (healthFactor >= 1.2) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else if (healthFactor > 1.0) {
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    } else if (healthFactor <= 0.0) {
      riskTextClassnames = "tracking-normal text-green-500 text-lg";
    } else {
      console.error("should not happen when repaying")
      riskTextClassnames = "tracking-normal text-red-500 text-lg";
    }

    return (
      <div className="pb-16 pt-20 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-3xl font-medium tracking-tight text-slate-900 sm:text-5xl">
      <div className='mb-4'>
          <span className="block text-gray-700 mb-1"> 
            Withdraw{' '} 
            <input 
              type="number" 
              className={supplyInputValueClassnames}
              value={additionalWithdrawAmount} 
              style={{'max-width': '270px'}}
              // style={supplyValueInvalid ? { borderColor: 'red', color: 'red', textarea} : {}}
              onChange={handleWithdrawAmountChange} 
              placeholder="0"
            /> 
            {' '} {positions.nonZeroUserReserves[0].reserve.symbol}
          </span>
          <br />
          <div className={riskTextClassnames}>
            {borrowRiskText.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <br />
          <div className="tracking-normal flex justify-center space-x-4">
              <Button
                className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className={`px-4 py-2 bg-green-500 text-white hover:bg-green-600 ${healthFactor <= 1.0 ? '' : ''}`}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
      </div>
      </h1>
      </div>
    )
  }

  const showBorrowEnded = () => {
    if (borrowEnded === 2) {
      return (<></>);
    }

    if (borrowEnded === 0) {
      return (
      <Button
        className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
        onClick={resetBorrow}
      >
        Failed, try again later!
      </Button>
      );
    }
    return (
    <Button
        className="px-4 py-2 bg-green-500 text-white hover:bg-green-600"
        onClick={afterSucceded}
      >
        Succeeded
      </Button>
    );
  }

  const PopupBorrow = () => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-296">
          {isProcessing ? (
              <div className="flex flex-col justify-center items-center mb-6">
                Borrowing, please approve metamask
                <br/><br/>
              <div className="mb-4">
                {/* <AiOutlineLoading3Quarters className="animate-spin text-4xl text-blue-500" />
                <br></br> */}
                <div className="w-full">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 mb-2 text-gray-700"
                >
                  {step.status === 'success' && (
                    <FaCheckCircle className="text-green-500" />
                  )}
                  {step.status === 'error' && (
                    <FaTimesCircle className="text-red-500" />
                  )}
                  {step.status === 'in-progress' && (
                    <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
                  )}
                  {step.status === 'pending' && <span className="w-6"></span>}
                  <span>{step.text}</span>
                </div>
              ))}
            </div>
              </div>
              <div>
                {showBorrowEnded()}
              </div>
              </div>
            ) : (BorrowMore())}
        </div>
      </div>
    )
  }

  const PopupSupply = () => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-296">
          {isProcessing ? (
              <div className="flex flex-col justify-center items-center mb-6">
                Supplying, please approve metamask
                <br/><br/>
              <div className="mb-4">
                {/* <AiOutlineLoading3Quarters className="animate-spin text-4xl text-blue-500" />
                <br></br> */}
                <div className="w-full">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 mb-2 text-gray-700"
                >
                  {step.status === 'success' && (
                    <FaCheckCircle className="text-green-500" />
                  )}
                  {step.status === 'error' && (
                    <FaTimesCircle className="text-red-500" />
                  )}
                  {step.status === 'in-progress' && (
                    <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
                  )}
                  {step.status === 'pending' && <span className="w-6"></span>}
                  <span>{step.text}</span>
                </div>
              ))}
            </div>
              </div>
              <div>
                {showBorrowEnded()}
              </div>
              </div>
            ) : (SupplyMore())}
        </div>
      </div>
    )
  }
  
  const PopupRepay = () => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-296">
          {isProcessing ? (
              <div className="flex flex-col justify-center items-center mb-6">
                Repaying, please approve metamask
                <br/><br/>
              <div className="mb-4">
                {/* <AiOutlineLoading3Quarters className="animate-spin text-4xl text-blue-500" />
                <br></br> */}
                <div className="w-full">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 mb-2 text-gray-700"
                >
                  {step.status === 'success' && (
                    <FaCheckCircle className="text-green-500" />
                  )}
                  {step.status === 'error' && (
                    <FaTimesCircle className="text-red-500" />
                  )}
                  {step.status === 'in-progress' && (
                    <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
                  )}
                  {step.status === 'pending' && <span className="w-6"></span>}
                  <span>{step.text}</span>
                </div>
              ))}
            </div>
              </div>
              <div>
                {showBorrowEnded()}
              </div>
              </div>
            ) : (RepayMore())}
        </div>
      </div>
    )
  }

  const PopupWithdraw = () => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-296">
          {isProcessing ? (
              <div className="flex flex-col justify-center items-center mb-6">
                Withdrawing, please approve metamask
                <br/><br/>
              <div className="mb-4">
                {/* <AiOutlineLoading3Quarters className="animate-spin text-4xl text-blue-500" />
                <br></br> */}
                <div className="w-full">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 mb-2 text-gray-700"
                >
                  {step.status === 'success' && (
                    <FaCheckCircle className="text-green-500" />
                  )}
                  {step.status === 'error' && (
                    <FaTimesCircle className="text-red-500" />
                  )}
                  {step.status === 'in-progress' && (
                    <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
                  )}
                  {step.status === 'pending' && <span className="w-6"></span>}
                  <span>{step.text}</span>
                </div>
              ))}
            </div>
              </div>
              <div>
                {showBorrowEnded()}
              </div>
              </div>
            ) : (WithdrawMore())}
        </div>
      </div>
    )
  }

  const Popup = () => {
    if (chosenPopup === 1) {
      return (PopupBorrow());
    } else if (chosenPopup === 2) {
      return (PopupSupply());
    } else if (chosenPopup === 3) {
      return (PopupRepay());
    } else if (chosenPopup === 4) {
      return (PopupWithdraw());
    }
    return (<></>);
  }

  return (
    <section
      id="positions"
      aria-label="Your current borrows"
      className="relative overflow-hidden bg-teal-100 pb-28 pt-20 sm:py-32"
    >
    <Container className="pb-16 pt-20 text-center lg:pt-32">
      {OpenPositions()}
    </Container>
      {showPopup && Popup()}
    </section>
  )

}
