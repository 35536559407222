import { Container } from "./Container";

import React from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const HealthFactorFormula = () => {
  const formula = `\\text{HF} = \\frac{\\text{Total Collateral Value} \\times \\text{Liquidation Threshold}}{\\text{Total Borrowed Value}}`;

  return (
    <MathJaxContext>
      <div>
        <MathJax>{`\\[${formula}\\]`}</MathJax>
      </div>
    </MathJaxContext>
  );
};

export function Blog() {
  const BlogPost1 = () => {
    return (
      <div className="mx-auto max-w-3xl">
        <div class="relative h-16">
          <div class="absolute top-0 right-0 text-right p-4">
            <p class="text-gray-600 text-sm">
              <span>Date: September 02, 2024</span>
            </p>
          </div>
        </div>
        <h2
          id="faq-title"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl text-center"
        >
          What is DeFi Koala?
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          I have been working in the crypto industry since 2017, but since there
          is so much innovation going on it is hard to stay up with the latest
          improvements with most leading protocols while still working a full
          time job. 3 months ago I decided to invest my free time to learn about
          DeFi (decentralized finance) as much as possible. I already knew a
          fair amount of DeFi protocols but wanted to go more in depth. I got
          intrigued by crypto lending - why would someone borrow crypto? Are
          people who do that just crypto degens and/or crypto whales, or there
          is also potential for crypto loans for broader crypto audience,
          meaning people that bought crypto and just hold it.
        </p>
        <br />
        <h3
          id="faq-subtitle-1"
          className="font-display text-xl tracking-tight text-slate-900 sm:text-3xl"
        >
          Main problems with DeFi (and crypto borrowing)
        </h3>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          There are two main problems with DeFi that I see, these problems are
          particularly relevant to crypto lending but to other DeFi
          possibilities as well. First problem is <b>education</b>. I am a
          seasoned crypto developer and crypto holder and yet for me to be
          confident of taking out a crypto loan I went through the{" "}
          <a
            href="https://www.coursera.org/specializations/decentralized-finance-duke"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            DeFi course on coursera
          </a>{" "}
          which I recommend to anyone wanting to learn more on DeFi; read Aave
          documentation and built a pet project that uses Aave. Only then, was I
          confident enough to borrow crypto by using crypto. The second problem
          is the <b>user experience</b>. Don’t get me wrong, crypto loans are
          complex and I think Aave as a pioneer did a great job. But it is
          unrealistic that the casual user of crypto will take a loan on a UI
          that is full of buttons. First you need to be sure what a crypto loan
          is, then you need to know a bit of how ERC20 tokens work, and a lot of
          how risk is calculated on Aave. In order to take a loan on Aave, you
          first need to supply some crypto as collateral. But before you can do
          that you need to approve the ERC20 token you are supplying. You do not
          need to do it if you supply native ETH, as a side note. Both the
          approval and the supplying need to be signed with your wallet (e.g.
          Metamask), and both transactions required payment of gas. And the
          third transaction is actually taking a loan. This is the most
          dangerous, as it depends how much of a loan against your collateral
          you are taking. The larger the loan to collateral ratio the riskier
          the position. For example, lets say you supplied 10 000$ in ETH (price
          of ETH is 4000$), so it is 2,5 ETH, and you borrowed 3000 USDC (worth
          3000$). Aave will show that the health factor for this borrow is 2.67
          - but what does that mean? They simply say, if health factor falls
          under 1, your position might get liquidated, and that the health
          factor above 3 is safe. It is much easier to understand once you know
          the formula of health factor from aave, which is
          <br></br>
          <br></br>
          {HealthFactorFormula()}
          <br></br>
          In our example HF = 10000 x 0,8 / 3000 The liquidation factor for ETH
          is 0.8, meaning you can at max borrow 80% of Ethers worth in the
          borrowed asset. Once I finally took a loan through Aave, I was
          constantly doing mental calculations on how much the ETH needs to fall
          for my position to become unsafe. I realized, this is enough for me to
          make an experiment. I wanted to create a simpler process for making
          overcollaterlized loans through Aave. This is what DeFiKoala does.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          DeFi Koala
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          Defi Koala is for now a product that simplifies taking a crypto loan
          and (hopefully) does it with a more user friendly interface for people
          that had less exposure to DeFi. It does it by providing a simple
          sentence on the main page, where you state “I have X $ in ETH and want
          to borrow Y $ in BTC”, or any supported token. It is not confusing
          users with a table of all possible assets and a ton of buttons as
          Aave. Also, there are some small optimizations on the DeFi Koala
          lending contract, which does take loans by connecting to Aave pools,
          but it uses the Permit2 contract from Uniswap. Meaning that any user
          that already swapped their ERC20 tokens on Uniswap and granted a large
          allowance to the Permit2 contract, the DeFi Koala contract will notice
          this, and there is no gas needed to pay for approval, only a gasless
          signature permit is required. However, since the loan is made through
          DeFi Koala contract and not Aave directly, there is a one time setup
          needed, where credit delegation is needed for the supplied and
          borrowed asset.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          This is only the first version, and DeFi Koala will be updated to
          provide even better and more efficient experience. Until then we do
          not take any fees at all (apart from what Aave is already taking). The
          ides is not just to have this 1-click-loan product, but in general to
          have 1-click-defi products, that are now just to hard to use, or most
          crypto users are even not aware of all the possible strategies DeFi
          offers to increase the capital efficiency. Try out DeFi Koala and let
          us know either through email [community@defikoala.com] or{" "}
          <a
            href="https://x.com/IgorJerkovic"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            twitter
          </a>{" "}
          what you think!
        </p>
        <hr class="border-t border-gray-300 my-8"></hr>
      </div>
    );
  };

  const BlogPost2 = () => {
    return (
      <div className="mx-auto max-w-3xl">
        <div class="relative h-16">
          <div class="absolute top-0 right-0 text-right p-4">
            <p class="text-gray-600 text-sm">
              <span>Date: September 24, 2024</span>
            </p>
          </div>
        </div>
        <h2
          id="faq-title"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl text-center"
        >
          Why Would Anyone Use a Crypto Loan?
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          In the world of decentralized finance (DeFi), crypto loans have gained
          significant attention as more users explore the possibilities of
          leveraging their digital assets. But many people still wonder: Why
          would anyone want to borrow crypto in the first place? It might sound
          counterintuitive to take out a loan on something as volatile as
          cryptocurrency, but in reality, crypto loans provide a range of
          benefits that go beyond the traditional financial system. Here’s why
          crypto loans can be incredibly useful.
        </p>
        <br />
        <h3
          id="faq-subtitle-1"
          className="font-display text-xl tracking-tight text-slate-900 sm:text-3xl"
        >
          1. Access Liquidity Without Selling Your Assets
        </h3>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          One of the most common reasons people use crypto loans is to unlock
          liquidity without selling their crypto holdings. Let’s say you’ve been
          holding onto a significant amount of Bitcoin or Ethereum and believe
          that the value will increase in the long run. If you need cash today
          but don’t want to sell your crypto (potentially missing out on future
          price gains), you can take out a loan instead.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          By using your crypto as collateral, you can borrow stablecoins (such
          as USDC or DAI) that are pegged to the US dollar, or even fiat
          currency in some platforms, while still retaining ownership of your
          original assets. This strategy allows you to benefit from future price
          appreciation while gaining immediate liquidity for other investments,
          expenses, or even business needs.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          2. HODL While Meeting Short-Term Financial Needs
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          Many long-term crypto holders (HODLers) are committed to keeping their
          assets for the long run, waiting for potential price increases. But
          what if life throws a financial curveball, and you need funds for
          something urgent, like a car repair or unexpected medical bill?
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          Instead of selling your crypto to cover the short-term expense, you
          can use your holdings as collateral to borrow money. This allows you
          to meet immediate financial needs without sacrificing your long-term
          investment strategy.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          3. Avoid Taxable Events
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          In some jurisdictions, selling crypto is a taxable event, meaning you
          would owe capital gains tax on the profits from your sale. By taking
          out a loan using your crypto as collateral, you can avoid triggering a
          taxable event, since borrowing money against your assets is not
          considered a sale.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          This is a major advantage for people looking to access liquidity
          without incurring a hefty tax bill. Of course, tax laws can vary by
          country, so it’s important to consult a tax professional in your area,
          but in many cases, borrowing rather than selling can help you manage
          taxes more effectively.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          4. Leverage Your Investment
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          For more advanced users, crypto loans can be used to increase exposure
          to a particular asset, a strategy known as leveraging. Let’s say you
          believe that the value of Ethereum is going to rise substantially in
          the next few months. You can use your current Ethereum holdings as
          collateral to borrow more Ethereum (or stablecoins), which you can
          then reinvest into the asset you believe will grow.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          This strategy allows you to amplify potential gains but also increases
          the risk, as market volatility can lead to liquidation if the value of
          your collateral drops significantly. Leveraging should be approached
          with caution, but for those who understand the risks, it can be a
          powerful tool in a portfolio.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          5. Hedge Against Market Volatility
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          Crypto loans aren’t just for those who want to increase their
          exposure. They can also be used as a hedge against potential market
          downturns. By borrowing stablecoins or other assets that you think may
          rise in value while using volatile crypto as collateral, you can
          diversify your portfolio and reduce the overall risk.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          For example, if you believe that the price of Bitcoin may drop in the
          near future, you could borrow stablecoins while holding Bitcoin as
          collateral. If the price of Bitcoin does drop, the stablecoins you
          borrowed retain their value, providing you with a buffer against
          losses. You can also choose to borrow other crypto assets that you
          believe will perform well during a downturn, balancing your risk.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          6. Explore DeFi Opportunities
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          DeFi is a fast-evolving space filled with opportunities for earning,
          lending, and borrowing. Some users take out crypto loans to access
          yield farming or liquidity mining opportunities, where they can earn
          rewards by providing liquidity to decentralized exchanges or other
          DeFi platforms. By borrowing assets and reinvesting them in other DeFi
          products, savvy users can generate higher yields than what traditional
          financial systems offer.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          While these strategies often come with risks, they can be an appealing
          option for those who are well-versed in DeFi protocols and want to
          maximize returns on their investments.
        </p>
        <br />
        <h2
          id="faq-subtitle-2"
          className="font-display text-3xl tracking-tight text-slate-900 sm:text-3xl"
        >
          How DeFi Koala Helps Simplify Crypto Loans
        </h2>
        <br />
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          At DeFi Koala, we’re passionate about making decentralized finance
          easy and accessible for everyone. Whether you’re a seasoned crypto
          user or just getting started, navigating the complexities of crypto
          loans can be overwhelming. That’s where DeFi Koala comes in.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          We provide a simple, user-friendly interface that helps you understand
          your crypto borrowing options without needing a deep technical
          background. Here’s how DeFi Koala can help you with crypto loans:
        </p>
        <ul class="list-disc pl-6 space-y-4">
          <li className="mt-4 text-lg tracking-tight text-slate-700">
            <b>Risk Assessment:</b> You can use DeFi Koala to assess the risk of
            your current borrow position, even if you already have a loan on
            platforms like Aave. With a simple login using MetaMask, you can
            check how safe your collateral is, and whether you’re at risk of
            liquidation.
          </li>
          <li className="mt-4 text-lg tracking-tight text-slate-700">
            <b>Educational Resources: </b> We provide educational tools and
            resources to help you better understand how crypto loans work, the
            benefits, and the risks. DeFi doesn’t have to be complicated, and
            with DeFi Koala, you’ll have the support you need to make informed
            decisions.
          </li>
          <li className="mt-4 text-lg tracking-tight text-slate-700">
            <b>Easy Navigation:</b> Our platform simplifies the borrowing
            process, so you can access liquidity, leverage your investments, or
            hedge your portfolio with just a few clicks. We’re committed to
            building the best experience for both beginners and experienced DeFi
            users.
          </li>
        </ul>
        <hr class="border-t border-gray-300 my-8"></hr>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          Crypto loans offer a wide range of benefits, from providing liquidity
          to avoiding taxes (not to confuse it with tax evasion which is when a
          person or company escapes paying taxes illegally) and maximizing your
          investment potential. Whether you’re looking to HODL your assets or
          explore advanced strategies, DeFi Koala is here to guide you through
          the process and help you make the most of your crypto journey.
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700 indent-8">
          Curious about how DeFi Koala can help you with your crypto borrowing
          needs in more details?{" "}
          <a
            href="https://calendar.app.google/DKhCah8NH74yDR238"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            Schedule a call
          </a>{" "}
          with Igor, founder of DeFi Koala
        </p>
        <hr class="border-t border-gray-300 my-8"></hr>
      </div>
    );
  };

  return (
    <section
      id="blog"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <Container className="relative">
        {BlogPost2()}
        <br />
        <br />
        {BlogPost1()}
      </Container>
    </section>
  );
}
