import React from 'react';

export function TestnetBanner({config}) {
  const bannerStyle = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    backgroundColor: '#f0ad4e',
    color: 'white',
    padding: '10px',
    borderRadius: '0px',
    zIndex: 1000,
    fontSize: '14px',
  };

  return (
    config.NETWORK_ID === 11155111 ? (
    <div style={bannerStyle}>
      This is ETH Sepolia Testnet
    </div>
    ) : (<></>)
  );
};
