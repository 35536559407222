// import { Link } from 'react-router-dom';
import { Link } from 'react-scroll';

export function NavLink({ to, children }) {
  return (
    <Link
      to={to}
      smooth={true} 
      duration={500}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 cursor-pointer"
    >
      {children}
    </Link>
  )
}
