import React, { useState } from "react";
import { ethers } from "ethers";
import { Button } from "./Button";
import { Container } from "./Container";
import {
  borrow,
  checkAndApproveAllowance,
  depositETH,
  getMaxFeeData,
  submitPermit,
  approveDelegation,
  signPermit,
  justBorrow,
} from "../borrow";
import { FaCheckCircle, FaTimesCircle, FaSpinner } from "react-icons/fa";

import { MdLoop } from "react-icons/md"; // Import a different spinner icon
import { IoIosSync } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ReactGA from "react-ga4";

import config from "../config";

function findUSDC(reserves) {
  console.log("***************");
  for (let index in reserves) {
    let entry = reserves[index];
    console.log(entry.symbol.toLowerCase());
    if (
      !entry.isPaused &&
      entry.borrowingEnabled &&
      entry.symbol.toLowerCase() === "usdc"
    ) {
      return { asset: entry, index: index };
    }
  }
  console.log("DID NOT FIND USDC!");
  return { asset: null, index: 0 };
}

function findFirstBorrowableAsset(reserves) {
  const usdcAsset = findUSDC(reserves);
  // debugger;
  if (usdcAsset.asset != null) {
    return usdcAsset;
  }
  for (let index in reserves) {
    let entry = reserves[index];
    if (!entry.isPaused && entry.borrowingEnabled) {
      return { asset: entry, index: index };
    }
  }
  return { asset: null, index: 0 };
}

export function Hero({
  account,
  provider,
  signer,
  reserves,
  baseCurrencyData,
}) {
  const [amountOwned, setAmountOwned] = useState("10000");
  const [amountToBorrow, setAmountToBorrow] = useState("5000");
  const [crypto, setCrypto] = useState("ETH");
  const [loading, setLoading] = useState(false);
  const [selectedSupplyAssetName, setSelectedSupplyAssetName] = useState("");
  const [selectedSupplyIndex, setSelectedSupplyIndex] = useState(null);
  const [selectedSupplyAsset, setSelectedSupplyAsset] = useState(null);
  const firstReserve = reserves && reserves.length > 0 ? reserves[0] : null;

  const [notEnoughSupplyTokenText, setNotEnoughTokenText] = useState("");
  const [borrowRiskText, setBorrowRiskText] = useState("");

  const supplyAsset =
    selectedSupplyAsset != null ? selectedSupplyAsset : firstReserve;

  const firstBorrowableReserve = findFirstBorrowableAsset(reserves);
  const [selectedBorrowAssetName, setSelectedBorrowAssetName] = useState(
    firstBorrowableReserve.asset != null
      ? firstBorrowableReserve.asset.name
      : ""
  );

  const [selectedBorrowAsset, setSelectedBorrowAsset] = useState(null);

  const [selectedBorrowIndex, setSelectedBorrowIndex] = useState(
    firstBorrowableReserve.index
  );
  const borrowAsset =
    selectedBorrowAsset != null
      ? selectedBorrowAsset
      : firstBorrowableReserve.asset;

  const [showPopup, setShowPopup] = useState(false);
  const [supplyValueInvalid, setSupplyValueInvalid] = useState(false);

  const [healthFactor, setHealthFactor] = useState(10000.0);

  const [borrowEnded, setBorrowEnded] = useState(2);

  const [isProcessing, setIsProcessing] = useState(false);
  const [steps, setSteps] = useState([]);

  const checkBorrowPositionRisk = async (
    borrowAsset,
    supplyAsset,
    amountToBorrow,
    amountOwned
  ) => {
    if (parseInt(amountToBorrow) === 0 || amountToBorrow === "") {
      setHealthFactor(0);
      setBorrowRiskText("Can't borrow 0$");
      return;
    }

    const healthFactor =
      ((supplyAsset.reserveLiquidationThreshold / 10000) * amountOwned) /
      amountToBorrow;

    const collateralAtLiquidation =
      amountToBorrow / (supplyAsset.reserveLiquidationThreshold / 10000);

    const amountOwnedInToken =
      amountOwned /
      (supplyAsset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);
    const requiredAmountBN = ethers.utils.parseUnits(
      parseFloat(amountOwnedInToken).toFixed(supplyAsset.decimals).toString(),
      supplyAsset.decimals
    );
    const requiredAmount = ethers.utils.formatUnits(
      requiredAmountBN,
      supplyAsset.decimals
    );

    const priceOfUnderlayingAssetPerCoinAtLiquidation =
      collateralAtLiquidation / requiredAmount;
    console.log(
      `price of ${supplyAsset.symbol} at liquidation = ${priceOfUnderlayingAssetPerCoinAtLiquidation} USD`
    );
    const percentageDecreaseNeededToLiquidate =
      ((supplyAsset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd -
        priceOfUnderlayingAssetPerCoinAtLiquidation) /
        (supplyAsset.priceInMarketReferenceCurrency /
          baseCurrencyData.marketReferenceCurrencyPriceInUsd)) *
      100;

    const riskText = `One ${supplyAsset.symbol} is currently worth ${
      supplyAsset.priceInMarketReferenceCurrency /
      baseCurrencyData.marketReferenceCurrencyPriceInUsd
    }$. It would need to fall to ${parseFloat(
      priceOfUnderlayingAssetPerCoinAtLiquidation
    ).toFixed(2)}$ (a fall by ${parseFloat(
      percentageDecreaseNeededToLiquidate
    ).toFixed(2)}%) to risk liquidation.`;
    if (healthFactor >= 3.0) {
      setBorrowRiskText(`This is considered a safe borrow. ${riskText}`);
    } else if (healthFactor >= 2.0) {
      setBorrowRiskText(`This is considered a mildy risky borrow. ${riskText}`);
    } else if (healthFactor >= 1.1) {
      setBorrowRiskText(
        `This is considered a fairly risky borrow. ${riskText}`
      );
    } else if (healthFactor > 1.0) {
      setBorrowRiskText(`This is considered a very risky borrow. ${riskText}`);
    } else {
      setBorrowRiskText(
        `Can't borrow that amount as it is below the liquidation threshold. You can borrow at max ${
          (supplyAsset.reserveLiquidationThreshold / 10000) * amountOwned
        }$ but you would risk immediate liquidation.`
      );
    }
    setHealthFactor(healthFactor);
  };

  const checkEthBalance = async (asset, amountOwned) => {
    console.log(asset);
    const tokenAddress = asset.underlyingAsset;

    const balance = await provider.getBalance(account);
    const decimals = 18;

    const amountOwnedInToken =
      amountOwned /
      (asset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);

    const balanceBN = ethers.BigNumber.from(balance);

    const amountOwnedInUSD =
      (ethers.utils.formatUnits(balanceBN, decimals) *
        asset.priceInMarketReferenceCurrency) /
      baseCurrencyData.marketReferenceCurrencyPriceInUsd;

    console.log(decimals);
    const requiredAmountBN = ethers.utils.parseUnits(
      parseFloat(amountOwnedInToken).toFixed(decimals).toString(),
      decimals
    );

    console.log(
      `LALALA You have ${ethers.utils.formatUnits(balanceBN, decimals)} ${
        asset.name
      } which is worth ${amountOwnedInUSD} $ but should have at least ${parseFloat(
        ethers.utils.formatUnits(requiredAmountBN, decimals)
      ).toFixed(2)} ${asset.name} for chosen $ amount`
    );

    if (balanceBN.lt(requiredAmountBN)) {
      setNotEnoughTokenText(
        `You have ${ethers.utils.formatUnits(balanceBN, decimals)} ${
          asset.name
        } which is worth ${amountOwnedInUSD} $ but should have at least ${parseFloat(
          ethers.utils.formatUnits(requiredAmountBN, decimals)
        ).toFixed(2)} ${asset.name} for chosen $ amount`
      );
      setSupplyValueInvalid(true);
      return false;
    }
    setNotEnoughTokenText("");
    setSupplyValueInvalid(false);
    checkBorrowPositionRisk(borrowAsset, asset, amountToBorrow, amountOwned);
    return true;
  };

  const checkTokenBalance = async (asset, amountOwned) => {
    if (!account) {
      return;
    }

    if (amountOwned === 0 || amountOwned === "" || amountOwned === "0") {
      setNotEnoughTokenText(`Can't supply 0 ${asset.symbol}`);
      setSupplyValueInvalid(true);
      return;
    }

    if (asset.id === "ID_ETH") {
      await checkEthBalance(asset, amountOwned);
      return;
    }

    console.log(asset);
    const tokenAddress = asset.underlyingAsset;

    const tokenABI = [
      "function balanceOf(address owner) view returns (uint256)",
      "function decimals() view returns (uint8)",
    ];

    const tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);

    const balance = await tokenContract.balanceOf(account);
    const decimals = await tokenContract.decimals();

    const amountOwnedInToken =
      amountOwned /
      (asset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);

    const balanceBN = ethers.BigNumber.from(balance);

    const amountOwnedInUSD =
      (ethers.utils.formatUnits(balanceBN, decimals) *
        asset.priceInMarketReferenceCurrency) /
      baseCurrencyData.marketReferenceCurrencyPriceInUsd;

    console.log(decimals);
    const requiredAmountBN = ethers.utils.parseUnits(
      parseFloat(amountOwnedInToken).toFixed(decimals).toString(),
      decimals
    );

    console.log(
      `LALALA You have ${ethers.utils.formatUnits(balanceBN, decimals)} ${
        asset.name
      } which is worth ${amountOwnedInUSD} $ but should have at least ${parseFloat(
        ethers.utils.formatUnits(requiredAmountBN, decimals)
      ).toFixed(2)} ${asset.name} for chosen $ amount`
    );

    if (balanceBN.lt(requiredAmountBN)) {
      setNotEnoughTokenText(
        `You have ${ethers.utils.formatUnits(balanceBN, decimals)} ${
          asset.name
        } which is worth ${amountOwnedInUSD} $ but should have at least ${parseFloat(
          ethers.utils.formatUnits(requiredAmountBN, decimals)
        ).toFixed(2)} ${asset.name} for chosen $ amount`
      );
      setSupplyValueInvalid(true);
      return false;
    }
    setNotEnoughTokenText("");
    setSupplyValueInvalid(false);
    checkBorrowPositionRisk(borrowAsset, asset, amountToBorrow, amountOwned);
    return true;
  };

  const handleAmountOwnedChange = (e) => {
    setAmountOwned(e.target.value);
    checkTokenBalance(supplyAsset, e.target.value);
  };

  const handleAmountToBorrowChange = (e) => {
    setAmountToBorrow(e.target.value);
    checkBorrowPositionRisk(
      borrowAsset,
      supplyAsset,
      e.target.value,
      amountOwned
    );
  };

  const handleCryptoChange = (e) => {
    setCrypto(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!account) {
      alert("Please login with Metamask first.");
      return;
    }

    // alert(`I have ${amountOwned} $ worth of ${crypto} and want to borrow ${amountToBorrow} $`);

    const permit2Address = "0x000000000022D473030F116dDEE9F6B43aC78BA3";

    // const tokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // DAI token //'<Token_Address>';
    // const aTokenAddress = '0x22675C506A8FC26447aFFfa33640f6af5d4D4cF0';
    // const debtTokenAddress = '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357'; // '0x6d906e526a4e2ca02097ba9d0caa3c382f52278e'; // EURS token // '<Debt_Token_Address>';

    console.log({ supplyAsset });
    console.log({ borrowAsset });
    console.log("END");

    console.log(
      `Supplying ${supplyAsset.name} to borrow ${borrowAsset.name} ...`
    );

    const tokenAddress = supplyAsset.underlyingAsset; // DAI token //'<Token_Address>';
    const aTokenAddress = supplyAsset.variableDebtTokenAddress;
    const debtTokenAddress = borrowAsset.underlyingAsset; //
    const aDebtTokenAddress = borrowAsset.variableDebtTokenAddress;

    console.log(tokenAddress);
    console.log(aTokenAddress);

    const lendingPoolAddress = config.DEFI_KOALA_LENDING_CONTRACT; //'0x43da3abea427c2bee3a960a5101b99261cd723b3'; //0x95B02439Bfc648E244cbEaDf3a7095d20Ac14f1E'; // Sepolia 1click loan contract // '<Lending_Pool_Address>';
    // const amount = ethers.utils.parseUnits('300', supplyAsset.decimals); // Adjust the amount as necessary
    // const debtAmount = ethers.utils.parseUnits('50', borrowAsset.decimals); // ethers.utils.parseUnits('5000', 2); // Adjust the debt amount as necessary

    const amount = supplyAssetAmountChosen();
    const debtAmount = borrowAssetAmountChosen();
    console.log(amount);
    console.log(debtAmount);

    const delegateApproveAmount = ethers.utils.parseUnits(
      "1000000000",
      supplyAsset.decimals
    ); // TODO: check if aToken and Token always have same decimals
    const delegateApproveBorrowAmount = ethers.utils.parseUnits(
      "1000000000",
      borrowAsset.decimals
    ); // TODO: check if aToken and Token always have same decimals

    setLoading(true);

    const billionSupply = ethers.utils.parseUnits(
      "1000000000",
      supplyAsset.decimals
    );

    try {
      console.log("Called borrow");
      const deadline = Math.floor(Date.now() / 1000) + 3600; // deadline
      const startTime = performance.now();
      //await borrow(signer, tokenAddress, amount, provider, permit2Address, aTokenAddress, aDebtTokenAddress, lendingPoolAddress, delegateApproveAmount, delegateApproveBorrowAmount, currentTime, debtTokenAddress, debtAmount);

      var currentSteps = [];
      var iter = -1;
      if (supplyAsset.id !== "ID_ETH") {
        const shouldGoIn1 = await checkAndApproveAllowance(
          signer,
          tokenAddress,
          amount,
          provider,
          permit2Address,
          true
        );
        if (shouldGoIn1) {
          try {
            iter++;
            currentSteps.push({
              text: `Approving allowance for ${supplyAsset.symbol}`,
              status: "in-progress",
            });
            setSteps(currentSteps);
            await checkAndApproveAllowance(
              signer,
              tokenAddress,
              billionSupply,
              provider,
              permit2Address
            );
            var currentSteps2 = currentSteps.slice();
            currentSteps2[iter].status = "success";
            setSteps(currentSteps2);
            currentSteps = currentSteps2.slice();
          } catch (err) {
            var currentSteps2 = currentSteps.slice();
            currentSteps2[iter].status = "error";
            setSteps(currentSteps2);
            currentSteps = currentSteps2.slice();
            setBorrowEnded(0);
            return false;
          }
        }

        const shouldGoIn2 = await approveDelegation(
          aTokenAddress,
          lendingPoolAddress,
          delegateApproveAmount,
          signer,
          provider,
          amount,
          true
        );
        if (shouldGoIn2) {
          try {
            iter++;
            currentSteps.push({
              text: `Approving delegation for ${supplyAsset.symbol}`,
              status: "in-progress",
            });
            setSteps(currentSteps);
            await approveDelegation(
              aTokenAddress,
              lendingPoolAddress,
              delegateApproveAmount,
              signer,
              provider,
              amount
            );
            var currentSteps2 = currentSteps.slice();
            currentSteps2[iter].status = "success";
            setSteps(currentSteps2);
            currentSteps = currentSteps2.slice();
          } catch (err) {
            var currentSteps2 = currentSteps.slice();
            currentSteps2[iter].status = "error";
            setSteps(currentSteps2);
            setBorrowEnded(0);
            return false;
          }
        }
      }

      const shouldGoIn3 = await approveDelegation(
        aDebtTokenAddress,
        lendingPoolAddress,
        delegateApproveBorrowAmount,
        signer,
        provider,
        debtAmount,
        true
      );
      if (shouldGoIn3) {
        try {
          iter++;

          currentSteps.push({
            text: `Approving delegation for ${borrowAsset.symbol}`,
            status: "in-progress",
          });
          var currentSteps2 = currentSteps.slice();
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          await approveDelegation(
            aDebtTokenAddress,
            lendingPoolAddress,
            delegateApproveBorrowAmount,
            signer,
            provider,
            debtAmount
          );
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = "success";
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
        } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = "error";
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(0);
          return false;
        }
      }

      if (supplyAsset.id === "ID_ETH") {
        const shouldGoIn2 = await approveDelegation(
          aTokenAddress,
          lendingPoolAddress,
          delegateApproveAmount,
          signer,
          provider,
          amount,
          true
        );
        if (shouldGoIn2) {
          try {
            iter++;
            currentSteps.push({
              text: `Approving delegation for WETH`,
              status: "in-progress",
            });
            setSteps(currentSteps);
            await approveDelegation(
              aTokenAddress,
              lendingPoolAddress,
              delegateApproveAmount,
              signer,
              provider,
              amount
            );
            var currentSteps2 = currentSteps.slice();
            currentSteps2[iter].status = "success";
            setSteps(currentSteps2);
            currentSteps = currentSteps2.slice();
          } catch (err) {
            var currentSteps2 = currentSteps.slice();
            currentSteps2[iter].status = "error";
            setSteps(currentSteps2);
            setBorrowEnded(0);
            return false;
          }
        }

        try {
          iter++;
          currentSteps.push({ text: `Depositing ETH`, status: "in-progress" });
          setSteps(currentSteps);
          await depositETH(
            config.AAVE_WETH_GATEWAY,
            config.POOL,
            signer,
            provider,
            amount
          );
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = "success";
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
        } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = "error";
          setSteps(currentSteps2);
          setBorrowEnded(0);
          return false;
        }

        currentSteps.push({ text: "Borrowing", status: "in-progress" });
        setSteps(currentSteps);
        try {
          iter++;
          await justBorrow(
            lendingPoolAddress,
            provider,
            signer,
            debtTokenAddress,
            debtAmount
          );
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = "success";
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(1);
        } catch (err) {
          var currentSteps2 = currentSteps.slice();
          currentSteps2[iter].status = "error";
          setSteps(currentSteps2);
          currentSteps = currentSteps2.slice();
          setBorrowEnded(0);
          return false;
        }
        return true;
      }

      const callerAddress = await signer.getAddress();
      const nonce = await provider.getTransactionCount(callerAddress);
      console.log("NONCE = ", nonce);

      currentSteps.push({ text: "Signing Permit", status: "in-progress" });
      setSteps(currentSteps);
      var permitSignature;
      try {
        iter++;
        permitSignature = await signPermit(
          signer,
          provider,
          tokenAddress,
          permit2Address,
          amount,
          lendingPoolAddress,
          deadline,
          nonce
        );
        console.log("Potpisano: ", permitSignature);
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = "success";
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = "error";
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }

      currentSteps.push({
        text: `Borrowing ${borrowAsset.symbol}`,
        status: "in-progress",
      });
      setSteps(currentSteps);
      try {
        iter++;
        await submitPermit(
          permitSignature,
          lendingPoolAddress,
          provider,
          signer,
          tokenAddress,
          amount,
          debtTokenAddress,
          debtAmount,
          deadline,
          nonce
        );
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = "success";
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(1);
      } catch (err) {
        var currentSteps2 = currentSteps.slice();
        currentSteps2[iter].status = "error";
        setSteps(currentSteps2);
        currentSteps = currentSteps2.slice();
        setBorrowEnded(0);
        return false;
      }

      const endTime = performance.now();
      const timeSpent = endTime - startTime;
      console.log(`Time spent borrowing: ${timeSpent} ms`);
    } catch (err) {
      console.log("Error while borrowing:", err);
    }
    setLoading(false);
    return true;
  };

  function trimTrailingZeros(value) {
    // Convert the number to a string and trim trailing zeros without rounding
    return parseFloat(
      value
        .toString()
        .replace(/(\.\d*?[1-9])0+$/g, "$1")
        .replace(/\.0+$/, "")
    ).toString();
  }

  function supplyAssetAmountChosen() {
    const supplyTokens =
      amountOwned /
      (supplyAsset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);
    const supplyTokensFixed = parseFloat(supplyTokens).toFixed(
      supplyAsset.decimals
    );
    return ethers.utils.parseUnits(supplyTokensFixed, supplyAsset.decimals);
  }

  function borrowAssetAmountChosen() {
    const borrowTokens =
      amountToBorrow /
      (borrowAsset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);
    const borrowTokensFixed = parseFloat(borrowTokens).toFixed(
      borrowAsset.decimals
    );
    return ethers.utils.parseUnits(borrowTokensFixed, borrowAsset.decimals);
  }

  const confirmationText = (e) => {
    const supplyTokens =
      amountOwned /
      (supplyAsset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);
    const supplyTokensFixed = parseFloat(supplyTokens).toFixed(
      supplyAsset.decimals
    );
    const borrowTokens =
      amountToBorrow /
      (borrowAsset.priceInMarketReferenceCurrency /
        baseCurrencyData.marketReferenceCurrencyPriceInUsd);
    const borrowTokensFixed = parseFloat(borrowTokens).toFixed(
      borrowAsset.decimals
    );
    return (
      <p>
        Supplying{" "}
        <b>
          {trimTrailingZeros(supplyTokensFixed)} {supplyAsset.symbol}
        </b>{" "}
        (worth {amountOwned}$) as collateral to borrow{" "}
        <b>
          {trimTrailingZeros(borrowTokensFixed)} {borrowAsset.symbol}
        </b>{" "}
        (worth ${amountToBorrow}$)
      </p>
    );
  };

  const handleSupplyChange = (event) => {
    setSelectedSupplyAssetName(event.target.value);
    setSelectedSupplyIndex(event.target.selectedIndex);
    setSelectedSupplyAsset(reserves[event.target.selectedIndex]);
    console.log("supply asset");
    console.log(selectedSupplyIndex);
    console.log(selectedSupplyAsset);
    console.log(reserves[event.target.selectedIndex]);
    console.log("supply asset END");
    console.log(reserves);
    console.log(`Selected supply asset: ${event.target.value}`);
    console.log(`Selected supply index: ${event.target.selectedIndex}`);
    checkTokenBalance(reserves[event.target.selectedIndex], amountOwned);
  };

  const handleBorrowChange = (event) => {
    setSelectedBorrowAsset(reserves[event.target.selectedIndex]);
    setSelectedBorrowAssetName(event.target.value);
    setSelectedBorrowIndex(event.target.selectedIndex);
    console.log("borrow asset");
    console.log(selectedBorrowAsset);
    console.log(reserves[event.target.selectedIndex]);
    console.log("borrow asset END");
    console.log(`Selected borrow asset: ${event.target.value}`);
    console.log(`Selected borrow index: ${event.target.selectedIndex}`);
    checkBorrowPositionRisk(
      reserves[event.target.selectedIndex],
      supplyAsset,
      amountToBorrow,
      amountOwned
    );
  };

  const handleBorrow = () => {
    // Add borrowing logic here
    setShowPopup(false);
    console.log(`Borrowing ${amountToBorrow} USDC using ${crypto}`);
  };

  var supplyInputValueClassnames =
    "tracking-normal pl-3 pr-10 py-2 rounded-md text-5xl";
  if (supplyValueInvalid) {
    supplyInputValueClassnames +=
      " input-invalid focus:ring-red-500 focus:outline-none";
  } else {
    supplyInputValueClassnames +=
      " focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 focus:outline-none";
  }

  var riskTextClassnames = "tracking-normal text-red-500 text-lg";
  if (healthFactor >= 3.0) {
    riskTextClassnames = "tracking-normal text-green-500 text-lg";
  } else if (healthFactor >= 2.0) {
    riskTextClassnames = "tracking-normal text-orange-500 text-lg";
  } else if (healthFactor >= 1.2) {
    riskTextClassnames = "tracking-normal text-red-500 text-lg";
  } else if (healthFactor > 1.0) {
    riskTextClassnames = "tracking-normal text-red-500 text-lg";
  } else {
    riskTextClassnames = "tracking-normal text-red-500 text-lg";
  }

  const getLoanData = () => {
    if (supplyAsset !== null && borrowAsset !== null) {
      return {
        supply: {
          asset: selectedSupplyAssetName,
          amount: amountOwned,
        },
        borrow: {
          asset: selectedBorrowAssetName,
          amount: amountToBorrow,
        },
      };
    }
    return {};
  };

  const handleConfirm = (e) => {
    if (!account) {
      resetBorrow();
      alert("Please login with Metamask first.");
      return;
    }
    ReactGA.event({
      category: "engagement",
      action: "button_click",
      label: "hero_loan_confirm",
      user_id: account !== null ? account : "anonymous",
      user_preferences: JSON.stringify(getLoanData()),
    });
    setIsProcessing(true);
    setShowPopup(true);
    handleSubmit(e);
  };

  const processSteps = async () => {
    for (let i = 0; i < steps.length; i++) {
      // Set current step to in-progress
      updateStepStatus(i, "in-progress");

      // Simulate a delay for processing each step
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Randomly decide if the step is successful or failed for demo purposes
      const isSuccess = Math.random() > 0.0;
      updateStepStatus(i, isSuccess ? "success" : "error");

      // If a step fails, stop further processing
      if (!isSuccess) break;
    }

    //setIsProcessing(false);
  };

  const updateStepStatus = (index, status) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, i) =>
        i === index ? { ...step, status: status } : step
      )
    );
  };

  const addToken = async (asset) => {
    const tokenAddress = asset.underlyingAsset;
    const tokenSymbol = asset.symbol;
    const tokenDecimals = asset.decimals;

    try {
      // Check if MetaMask is available
      if (window.ethereum) {
        // Request to add token to MetaMask
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // This denotes the type of token standard
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
            },
          },
        });

        if (wasAdded) {
          console.log("Token added successfully!");
        } else {
          console.log("Token addition rejected.");
        }
      } else {
        console.log("MetaMask is not installed");
      }
    } catch (error) {
      console.error("Error adding token:", error);
    }
  };

  const addTokensToMetamask = async () => {
    afterSucceded();

    await addToken(borrowAsset);
    //await addToken(supplyAsset) TODO: add token of collateral, needs to fetch token name/decimals etc, it is the aToken
  };

  const afterSucceded = () => {
    ReactGA.event({
      category: "engagement",
      action: "button_click",
      label: "hero_loan_success",
      user_id: account !== null ? account : "anonymous",
      user_preferences: JSON.stringify(getLoanData()),
    });
    resetBorrow();
    window.location.reload();
  };

  const afterFailure = () => {
    ReactGA.event({
      category: "engagement",
      action: "button_click",
      label: "hero_loan_success",
      user_id: account !== null ? account : "anonymous",
      user_preferences: JSON.stringify(getLoanData()),
    });
    resetBorrow();
  };

  const resetBorrow = () => {
    setIsProcessing(false);
    setSteps([]);
    setShowPopup(false);
    setBorrowEnded(2);
  };

  const showBorrowEnded = () => {
    if (borrowEnded === 2) {
      return <></>;
    }

    if (borrowEnded === 0) {
      return (
        <Button
          className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
          onClick={afterFailure}
        >
          Failed, try again later!
        </Button>
      );
    }
    return (
      <div className="flex justify-center space-x-4">
        <Button
          className="px-4 py-2 bg-green-500 text-white hover:bg-green-600"
          onClick={afterSucceded}
        >
          Succeeded
        </Button>
        <Button
          className="px-4 py-2 bg-green-500 text-white hover:bg-green-600"
          onClick={addTokensToMetamask}
        >
          Add tokens to Metamask
        </Button>
      </div>
    );
  };

  const handleCancel = () => {
    setShowPopup(false);
    setIsProcessing(false);
    setSteps([]);
  };

  return (
    <Container className="pb-16 pt-20 text-center lg:pt-32" id="borrow">
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        <form>
          <div className="mb-4">
            <span className="block text-gray-700 mb-1">
              I have{" $ "}
              <input
                type="number"
                className={supplyInputValueClassnames}
                value={amountOwned}
                style={{ "max-width": "270px" }}
                // style={supplyValueInvalid ? { borderColor: 'red', color: 'red', textarea} : {}}
                onChange={handleAmountOwnedChange}
                placeholder="10000.00"
              />{" "}
              in{" "}
              <select
                className="tracking-normal pl-3 pr-10 py-2 h12 text-5xl border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                style={{ width: "220px" }}
                value={selectedSupplyAssetName}
                onChange={handleSupplyChange}
              >
                {reserves ? (
                  reserves.map((reserve, index) =>
                    reserve.usageAsCollateralEnabled ? (
                      <option key={index} value={reserve.address}>
                        {reserve.symbol}
                      </option>
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <></>
                )}
              </select>
            </span>
          </div>
          <div className="tracking-normal text-red-500 text-lg">
            {notEnoughSupplyTokenText}
          </div>
          <div className="mb-4">
            <span className="block text-gray-700 mb-1">
              {" "}
              and want to borrow
            </span>
            <span className="block text-gray-700 mb-1">
              ${" "}
              <input
                type="number"
                className="tracking-normal pl-3 pr-10 py-2 text-5xl border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                value={amountToBorrow}
                onChange={handleAmountToBorrowChange}
                style={{ "max-width": "270px" }}
                placeholder="5000.00"
              />{" "}
              in{" "}
              <select
                className="tracking-normal pl-3 pr-10 py-2 h12 text-5xl border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                style={{ width: "220px" }}
                value={borrowAsset != null ? borrowAsset.symbol : ""}
                onChange={handleBorrowChange}
              >
                {reserves ? (
                  reserves.map((reserve, index) =>
                    reserve.borrowingEnabled ? (
                      <option key={index} value={reserve.address}>
                        {reserve.symbol}
                      </option>
                    ) : (
                      <option hidden={true} key={index} value={reserve.address}>
                        {reserve.symbol}
                      </option>
                    )
                  )
                ) : (
                  <></>
                )}
              </select>
            </span>
          </div>
          <div className={riskTextClassnames}>{borrowRiskText}</div>
          <Button
            className={`tracking-normal ${
              supplyValueInvalid || healthFactor <= 1.0
                ? "bg-gray-400 cursor-not-allowed"
                : ""
            }`}
            type="submit"
            disabled={supplyValueInvalid || healthFactor <= 1.0}
            onClick={(e) => {
              e.preventDefault();
              setShowPopup(true);
              ReactGA.event({
                category: "engagement",
                action: "button_click",
                label: "hero_loan_submit",
                user_id: account !== null ? account : "anonymous",
              });
            }}
          >
            Submit
          </Button>
        </form>
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
        Crypto lending platforms are hard to use. DeFi Koala offers 1 click
        loans. We never hold any of your coins, the UI works with non-custodial
        wallets. For a limited time, we don't take any fees.
      </p>
      <div className="mt-10 flex justify-center gap-x-6">
        <Button href="/register">Get 6 months free</Button>
        <Button
          href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          variant="outline"
        >
          <svg
            aria-hidden="true"
            className="h-3 w-3 flex-none fill-blue-600 group-active:fill-current"
          >
            <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
          </svg>
          <span className="ml-3">Watch video</span>
        </Button>
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
              {isProcessing ? (
                <div className="flex flex-col justify-center items-center mb-6">
                  Borrowing, please approve metamask after each step
                  <br />
                  <br />
                  <div className="mb-4">
                    {/* <AiOutlineLoading3Quarters className="animate-spin text-4xl text-blue-500" />
                <br></br> */}
                    <div className="w-full">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2 mb-2 text-gray-700"
                        >
                          {step.status === "success" && (
                            <FaCheckCircle className="text-green-500" />
                          )}
                          {step.status === "error" && (
                            <FaTimesCircle className="text-red-500" />
                          )}
                          {step.status === "in-progress" && (
                            <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
                          )}
                          {step.status === "pending" && (
                            <span className="w-6"></span>
                          )}
                          <span>{step.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>{showBorrowEnded()}</div>
                </div>
              ) : borrowEnded === 2 ? (
                <div>
                  <p className="text-gray-700 mb-4">{confirmationText()}</p>
                  <div className="flex justify-center space-x-4">
                    <Button
                      className="px-4 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="px-4 py-2 bg-green-500 text-white hover:bg-green-600"
                      onClick={handleConfirm}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              ) : (
                <div>{showBorrowEnded()}</div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mt-36 lg:mt-44">
        <p className="font-display text-base text-slate-900">
          {notEnoughSupplyTokenText}
        </p>
      </div>
    </Container>
  );
}
