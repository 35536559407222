import React from 'react'
import clsx from 'clsx'
import '../index.css' // Adjust the path as necessary

export const metadata = {
  title: {
    template: '%s - TaxPal',
    default: 'TaxPal - Accounting made simple for small businesses',
  },
  description:
    'Most bookkeeping software is accurate, but hard to use. We make the opposite trade-off, and hope you don’t get audited.',
}

export default function RootLayout({ children }) {
  return (
    <html
      lang="en"
      className={clsx(
        'h-full scroll-smooth bg-white antialiased',
        'font-inter', // Apply the Inter font
        'font-lexend' // Apply the Lexend font
      )}
    >
      <body className="flex h-full flex-col">
        {children}
      </body>
    </html>
  )
}
