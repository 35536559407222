import { ethers } from 'ethers';

export const borrow = async(signer, tokenAddress, amount, provider, permit2Address, aTokenAddress, aDebtTokenAddress, lendingPoolAddress, delegateApproveAmount, delegateApproveBorrowAmount, deadline, debtTokenAddress, debtAmount) => {
    // checkAndApproveAllowance(signer, tokenAddress, amount, provider, permit2Address)
    //     .then(() => approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider))
    //     .catch(error => console.log("checkAndApproveAllowance ", error));

    await checkAndApproveAllowance(signer, tokenAddress, amount, provider, permit2Address);

    // TODO: check on first allowance user has enough tokens to at least supply to aave
    // TODO: implement awaiting on tx passed....
    // TODO: check passing in the nonce for permit2

    await approveDelegation(aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, debtAmount);

    await approveDelegation(aDebtTokenAddress, lendingPoolAddress, delegateApproveBorrowAmount, signer, provider, debtAmount);

    const callerAddress = await signer.getAddress();
    const nonce = await provider.getTransactionCount(callerAddress);

    const permitSignature = await signPermit(signer, provider, tokenAddress, permit2Address, amount, lendingPoolAddress, deadline, nonce);

    await submitPermit(permitSignature, lendingPoolAddress, provider, signer, tokenAddress, amount, debtTokenAddress, debtAmount, deadline, nonce);
}

export const getMaxFeeData = async (provider, chainId) => {
    const feeData = await provider.getFeeData();
    console.log(feeData)
    const maxPriorityFeePerGas = feeData.maxPriorityFeePerGas ? feeData.maxPriorityFeePerGas : ethers.utils.parseUnits('2', 'gwei'); // Adjust the multiplier as needed
    const maxFeePerGas = feeData.maxFeePerGas ? feeData.maxFeePerGas : ethers.utils.parseUnits('100', 'gwei'); // Adjust the multiplier as needed
    //const gasPrice = feeData.gasPrice ? feeData.gasPrice.mul(2).toString() : ethers.utils.parseUnits('100', 'gwei').toString(); // Adjust the multiplier as needed
    console.log(ethers.utils.formatUnits(maxPriorityFeePerGas, "gwei"), ethers.utils.formatUnits(maxFeePerGas, "gwei"))

    return {maxPriorityFeePerGas, maxFeePerGas};
  };

  export const depositETH = async (wethAddress, poolAddress, signer, provider, amount) => {
    console.log("Step #1: Depositing ETH");
    console.log("WETH ADDRESS ", wethAddress);
    console.log("POOL ADDRESS ", poolAddress);

    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const contract = new ethers.Contract(wethAddress, [
      'function depositETH(address pool, address onBehalfOf, uint16 referralCode) external payable'
    ], signer);

    const [owner] = await provider.listAccounts();

    try {
      const tx = await contract.depositETH(poolAddress, owner, 0, {
        gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
        maxFeePerGas,
        maxPriorityFeePerGas,
        value: amount
      });
      console.log('Transaction hash:', tx.hash);
      await tx.wait();
      console.log('Deposited ETH successfully');
    } catch (error) {
      console.error('Transaction failed:', error);
      throw new Error("Failed to deposit ETH: ", error)
    }
  }

  export const withdrawETH = async (wethAddress, poolAddress, signer, provider, amount) => {
    console.log("Step #1: Depositing ETH");
    console.log("WETH ADDRESS ", wethAddress);

    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const contract = new ethers.Contract(wethAddress, [
      'function withdrawETH(address, uint256 amount, address to) external'
    ], signer);

    const [owner] = await provider.listAccounts();

    try {
      const tx = await contract.withdrawETH(poolAddress, amount, owner, {
        gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
        maxFeePerGas,
        maxPriorityFeePerGas,
      });
      console.log('Transaction hash:', tx.hash);
      await tx.wait();
      console.log('Withdrawn ETH successfully');
    } catch (error) {
      console.error('Transaction failed:', error);
      throw new Error("Failed to withdraw ETH: ", error)
    }
  }

  export const submitPermit = async (permitSignature, lendingPoolAddress, provider, signer, tokenAddress, amount, debtTokenAddress, debtAmount, deadline, nonce) => {
    console.log("Step #4: Supply tokens to AAVE and borrow");
    if (!permitSignature) {
      alert('Please sign the permit first');
      return;
    }

    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const contract = new ethers.Contract(lendingPoolAddress, [
      'function supplyAndBorrowWithPermit2(address token, uint256 amount, address debtToken, uint256 debtAmount, uint256 nonce, uint256 deadline, uint8 v, bytes32 r, bytes32 s)',
    ], signer);

    const [owner] = await provider.listAccounts();
    // const nonce = 1; // TODO: namjesti nonce //await new ethers.Contract(tokenAddress, ['function nonces(address owner) view returns (uint256)'], signer).nonces(owner);

    const { v, r, s } = permitSignature;

    try {
      const tx = await contract.supplyAndBorrowWithPermit2(tokenAddress, amount, debtTokenAddress, debtAmount, nonce, deadline, v, r, s, {
        gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
        maxFeePerGas,
        maxPriorityFeePerGas,
      });
      console.log('Transaction hash:', tx.hash);
      await tx.wait();
      console.log('Borrowed successfully');
    } catch (error) {
      console.error('Transaction failed:', error);
      throw new Error("Failed to submit borrow: ", error)
    }
  };

  export const submitSupplyPermit = async (permitSignature, lendingPoolAddress, provider, signer, tokenAddress, amount, deadline, nonce) => {
    console.log("Step #4: Supply tokens to AAVE");
    if (!permitSignature) {
      alert('Please sign the permit first');
      return;
    }

    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const contract = new ethers.Contract(lendingPoolAddress, [
      'function supplyWithPermit2(address token, uint256 amount, uint256 nonce, uint256 deadline, uint8 v, bytes32 r, bytes32 s)'
    ], signer);

    const [owner] = await provider.listAccounts();
    // const nonce = 1; // TODO: namjesti nonce //await new ethers.Contract(tokenAddress, ['function nonces(address owner) view returns (uint256)'], signer).nonces(owner);

    const { v, r, s } = permitSignature;

    try {
      const tx = await contract.supplyWithPermit2(tokenAddress, amount, nonce, deadline, v, r, s, {
        gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
        maxFeePerGas,
        maxPriorityFeePerGas,
      });
      console.log('Transaction hash:', tx.hash);
      await tx.wait();
      console.log('Supplied successfully');
    } catch (error) {
      console.error('Transaction failed:', error);
      throw new Error("Failed to submit supply: ", error)
    }
  };

  export const submitRepayPermit = async (permitSignature, lendingPoolAddress, provider, signer, tokenAddress, amount, deadline, nonce) => {
    console.log("Repay tokens to AAVE");
    if (!permitSignature) {
      alert('Please sign the permit first');
      return;
    }

    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const contract = new ethers.Contract(lendingPoolAddress, [
      'function repayWithPermit2(address token, uint256 amount, uint256 nonce, uint256 deadline, uint8 v, bytes32 r, bytes32 s)'
    ], signer);

    const [owner] = await provider.listAccounts();
    // const nonce = 1; // TODO: namjesti nonce //await new ethers.Contract(tokenAddress, ['function nonces(address owner) view returns (uint256)'], signer).nonces(owner);

    const { v, r, s } = permitSignature;

    try {
      const tx = await contract.repayWithPermit2(tokenAddress, amount, nonce, deadline, v, r, s, {
        gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
        maxFeePerGas,
        maxPriorityFeePerGas,
      });
      console.log('Transaction hash:', tx.hash);
      await tx.wait();
      console.log('Repaid successfully');
    } catch (error) {
      console.error('Transaction failed:', error);
      throw new Error("Failed to submit supply: ", error)
    }
  };

  export const justBorrow = async (lendingPoolAddress, provider, signer, debtTokenAddress, debtAmount) => {
    console.log("Step Just borrow");
    
    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const contract = new ethers.Contract(lendingPoolAddress, [
      'function borrow(address debtToken, uint256 debtAmount)'
    ], signer);

    const [owner] = await provider.listAccounts();

    try {
      const tx = await contract.borrow(debtTokenAddress, debtAmount, {
        gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
        maxFeePerGas,
        maxPriorityFeePerGas,
      });
      console.log('Transaction hash:', tx.hash);
      await tx.wait();
      console.log('Borrowed successfully');
    } catch (error) {
      console.error('Transaction failed:', error);
      throw new Error("Failed to submit borrow: ", error)
    }
  };


export const checkAndApproveAllowance = async (signer, tokenAddress, amount, provider, permit2Address, justCheck = false) => {
    console.log("Step #1: Checking if allowance approval needed");
    if (!signer) {
      alert('Please connect MetaMask first');
      return new Error('Please connect MetaMask first');
    }

    const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = await getMaxFeeData(provider);

    const token = new ethers.Contract(tokenAddress, [
      'function allowance(address owner, address spender) view returns (uint256)',
      'function approve(address spender, uint256 amount) public returns (bool)',
    ], signer);

    const [owner] = await provider.listAccounts();
    const currentAllowance = await token.allowance(owner, permit2Address);

    if (currentAllowance.lt(amount)) {
        if (justCheck) { return 1; }
      console.log('Current allowance is not enough. Approving additional tokens...');
      try {
        // approve some very large amount to skip asking all the time
        const approveAmount = ethers.utils.parseUnits('100000000', 18);

        const tx = await token.approve(permit2Address, approveAmount, {
            maxFeePerGas,
            maxPriorityFeePerGas,
        });
        console.log('Approval transaction hash:', tx.hash);
        await tx.wait();
        console.log('Token approved successfully');
      } catch (error) {
        console.error('Failed to approve tokens:', error);
        throw new Error('Failed to approve tokens:', error);
      }
    } else {
      console.log('Current allowance is sufficient');
      if (justCheck) {
        return 0;
      }
    }
  };

  export const approveDelegation = async (aTokenAddress, lendingPoolAddress, delegateApproveAmount, signer, provider, debtAmount, justCheck = false) => {
    console.log("Step #2: Checking if approval for credit delegation is needed");
    const contract = new ethers.Contract(aTokenAddress, [
      "function approveDelegation(address delegatee, uint256 amount)",
      "function borrowAllowance(address fromUser, address toUser) external view returns (uint256)",
    ], signer);

    const [owner] = await provider.listAccounts();

    const { maxFeePerGas, maxPriorityFeePerGas } = await getMaxFeeData(provider);
    console.log("---")
    console.log(maxFeePerGas, maxPriorityFeePerGas);

    const currentAllowance = await contract.borrowAllowance(owner, lendingPoolAddress);

    if (currentAllowance.lt(debtAmount)) {
        if (justCheck) { return 1; }
        console.log('Current delegation allowance is not enough. Approving additional tokens...');
        try {
            const tx = await contract.approveDelegation(lendingPoolAddress, delegateApproveAmount, {
            gasLimit: ethers.utils.hexlify(1500000), // Set a manual gas limit
            maxFeePerGas: maxFeePerGas,
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            });
            console.log('Transaction hash:', tx.hash);
            await tx.wait();
            console.log('Token delegation approved successfully');
          } catch (error) {
            console.error('Transaction failed:', error);
            throw new Error('Failed to delegate allowance:', error);
          }
    } else {
        console.log('Current delegation allowance is sufficient');
        if (justCheck) { return 0; }
    }
  };

  export const signPermit = async (signer, provider, tokenAddress, permit2Address, amount, lendingPoolAddress, deadline, nonce) => {
    console.log("Step #3: Sign the permit to supply tokens to aave");
    if (!signer) {
      alert('Please connect MetaMask first');
      return;
    }

    const [owner] = await provider.listAccounts();
    const token = new ethers.Contract(tokenAddress, [
      'function nonces(address owner) view returns (uint256)',
      'function name() view returns (string)',
    ], signer);

    // const nonce = 1; // await token.nonces(owner);
    //const name = await token.name();
    //setTokenName(name);
    const version = '1';
    const chainId = (await provider.getNetwork()).chainId;

    const domain = {
      name: 'Permit2',
      // version,
      chainId,
      verifyingContract: permit2Address,
    };

    const types = {
      PermitTransferFrom: [
        { name: 'permitted', type: 'TokenPermissions' },
        { name: 'spender', type: 'address' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ],
      TokenPermissions: [
        { name: 'token', type: 'address' },
        { name: 'amount', type: 'uint256' },
      ],
    };

    const message = {
      permitted: {
        token: tokenAddress,
        amount: amount.toString(),
      },
      spender: lendingPoolAddress,
      nonce: nonce.toString(),
      deadline: deadline.toString(),
    };

    try {
      const signature = await signer._signTypedData(domain, types, message);
      const { v, r, s } = ethers.utils.splitSignature(signature);
    //   setPermitSignature({ v, r, s });
      console.log('Permit Signature:', { v, r, s });
      return { v, r, s }
    } catch(error) {
      console.log('Failed to sign permit');
      console.log(error);
      throw new Error('Failed to sign permit', error);
    }
};