import {
    UiPoolDataProvider,
    UiIncentiveDataProvider,
    ChainId,
} from '@aave/contract-helpers';
import * as markets from '@bgd-labs/aave-address-book';

const ENV = process.env.REACT_APP_ENV || 'production';

const CONFIG = {
  development: {
    NETWORK_NAME: 'ETH Sepolia - testnet',
    NETWORK_ID: 11155111,
    PROVIDER_URL: 'https://eth-sepolia.public.blastapi.io',
    AAVE_UI_POOL_DATA_PROVIDER: markets.AaveV3Sepolia.UI_POOL_DATA_PROVIDER,
    AAVE_UI_INCENTIVE_DATA_PROVIDER: markets.AaveV3Sepolia.UI_INCENTIVE_DATA_PROVIDER,
    AAVE_CHAIN_ID: ChainId.sepolia,
    AAVE_POOL_ADDRESSES_PROVIDER: markets.AaveV3Sepolia.POOL_ADDRESSES_PROVIDER,
    DEFI_KOALA_LENDING_CONTRACT: "0xef7de7ae78290a159c8281e26cc711ba3393dbf0",
    AAVE_WETH_GATEWAY: markets.AaveV3Sepolia.WETH_GATEWAY,
    POOL: markets.AaveV3Sepolia.POOL,

    // CHECK IF THESE ARE HELPFUL
    // ETH_USD_PRICE_FEED_ADDRESS: '0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419', // Replace with Sepolia address
    // UI_POOL_DATA_PROVIDER_ADDRESS: '0x6d22f033e6c3aEDc40c5D93A8D1b2dA22A35259f', // Replace with Sepolia address
    // UI_INCENTIVE_DATA_PROVIDER_ADDRESS: '0x7d24fD444B82F94e0CB92a1f1bB0A424870eE246', // Replace with Sepolia address
    // LENDING_POOL_ADDRESS_PROVIDER: '0x7d2768dE32b0b80b7a3454c06BdAcb4c66F4E366', // Replace with Sepolia address
  },
  production: {
    NETWORK_NAME: 'ETH Mainnet',
    NETWORK_ID: 1,
    PROVIDER_URL: 'https://eth-mainnet.public.blastapi.io',
    AAVE_UI_POOL_DATA_PROVIDER: '0x194324C9Af7f56E22F1614dD82E18621cb9238E7', //markets.AaveV3Ethereum.AAVE_PROTOCOL_DATA_PROVIDER,
    AAVE_UI_INCENTIVE_DATA_PROVIDER: markets.AaveV3Ethereum.UI_INCENTIVE_DATA_PROVIDER,
    AAVE_CHAIN_ID: ChainId.mainnet,
    AAVE_POOL_ADDRESSES_PROVIDER: markets.AaveV3Ethereum.POOL_ADDRESSES_PROVIDER,
    DEFI_KOALA_LENDING_CONTRACT: "0x512b5297b77c2486cddbf4adbaf191e87eef06a9",
    AAVE_WETH_GATEWAY: markets.AaveV3Ethereum.WETH_GATEWAY,
    POOL: markets.AaveV3Ethereum.POOL,


    // CHECK IF THESE ARE HELPFUL
    // ETH_USD_PRICE_FEED_ADDRESS: '0xF79D6aFBb6dA890132F9D7c355e3015f15F3406F', // Mainnet address
    // UI_POOL_DATA_PROVIDER_ADDRESS: '0x6d22f033e6c3aEDc40c5D93A8D1b2dA22A35259f', // Mainnet address
    // UI_INCENTIVE_DATA_PROVIDER_ADDRESS: '0x7d24fD444B82F94e0CB92a1f1bB0A424870eE246', // Mainnet address
    // LENDING_POOL_ADDRESS_PROVIDER: '0x7d2768dE32b0b80b7a3454c06BdAcb4c66F4E366', // Mainnet address
  },
};

const SUPPORTED_NETWORKS = Object.values(CONFIG).map(config => config.NETWORK_ID);

const config = CONFIG[ENV];

export default config;
export { SUPPORTED_NETWORKS, CONFIG };
